var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "step-detail" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "detail-content" }, [
      _c("div", { staticClass: "title" }, [_vm._v("\n      服务信息\n    ")]),
      _vm._v(" "),
      _c("ul", [
        _vm._m(1),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n          检测者姓名\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v("\n          " + _vm._s(_vm.detail.name) + "\n        "),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n          性别\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              "\n          " +
                _vm._s(!!_vm.detail.gender ? "男" : "女") +
                "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n          联系电话\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v("\n          " + _vm._s(_vm.detail.tel) + "\n        "),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n          联系地址\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v("\n          " + _vm._s(_vm.detail.address) + "\n        "),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n          备注\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v("\n          " + _vm._s(_vm.detail.comment) + "\n        "),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip" }, [
      _vm._v(
        "\n    您好，您的幽门螺旋杆菌、便隐血双检测自检申请已提交，\n    我们会尽快寄出检测试纸，收到试纸后按照操作说明进行自检，若有疑问请拨打客服电话\n    "
      ),
      _c("br"),
      _vm._v(" "),
      _c("a", { attrs: { href: "tel:400-6065581" } }, [_vm._v("400-6065581")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "name" }, [
        _vm._v("\n          检测项目\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "value" }, [
        _vm._v("\n          幽门螺旋杆菌、便隐血双检\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }