import _defineProperty from "/var/jenkins_home/workspace/dashu-h5-test/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import _interopRequireWildcard from "/var/jenkins_home/workspace/dashu-h5-test/node_modules/@babel/runtime-corejs2/helpers/esm/interopRequireWildcard.js";

var _ref;

import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','user']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: "/",
  name: "home",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/index/index"));
    });
  },
  meta: {
    title: "首页",
    keepAlive: true
  },
  hidden: false
}, {
  name: "index",
  path: "/index",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/index/index"));
    });
  },
  meta: {
    title: "首页",
    keepAlive: true
  },
  hidden: false
}, {
  name: "index_test",
  path: "/index/test",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/index/test"));
    });
  },
  meta: {
    title: "测试首页",
    keepAlive: true
  },
  hidden: true
}, {
  name: "diagnose-detail",
  path: "/diagnose-detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/diagnose-detail/index"));
    });
  },
  meta: {
    title: "详情",
    keepAlive: false
  },
  hidden: false
}, {
  name: "manual",
  path: "/manual",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/manual/index"));
    });
  },
  meta: {
    title: "使用手册",
    keepAlive: false
  },
  hidden: false
}, {
  name: "no-right",
  path: "/no-right",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/no-right/index"));
    });
  },
  meta: {
    title: "说明",
    keepAlive: false
  },
  hidden: false
}, {
  name: "logout-out",
  path: "/logout-out",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/logout-out/index"));
    });
  },
  meta: {
    title: "登录过期",
    keepAlive: false
  },
  hidden: false
}, {
  name: "hqb-register",
  path: "/hqb/register",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/hqb/register/index"));
    });
  },
  meta: {
    title: "注册",
    keepAlive: false
  },
  hidden: false
}, {
  name: "ynhl-register",
  path: "/ynhl/register",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/hqb/register/index"));
    });
  },
  meta: {
    title: "注册",
    keepAlive: false
  },
  hidden: false
}, {
  name: "hqb-login",
  path: "/hqb/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/hqb/login/index"));
    });
  },
  meta: {
    title: "登录",
    keepAlive: false
  },
  hidden: false
}, {
  name: "ynhl-login",
  path: "/ynhl/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/hqb/login/index"));
    });
  },
  meta: {
    title: "登录",
    keepAlive: false
  },
  hidden: false
}, {
  name: "hqb-detail",
  path: "/hqb/detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/hqb/detail/index"));
    });
  },
  meta: {
    title: "健康服务",
    keepAlive: false
  },
  hidden: false
}, {
  name: "hqb-audit-pending",
  path: "/hqb/audit-pending",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/hqb/audit-result/index"));
    });
  },
  meta: {
    title: "审核中",
    keepAlive: false
  },
  hidden: false
}, {
  name: "hqb-audit-fail",
  path: "/hqb/audit-fail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/hqb/audit-result/index"));
    });
  },
  meta: {
    title: "审核不通过",
    keepAlive: false
  },
  hidden: false
}, {
  name: "hqb-audit-success",
  path: "/hqb/audit-success",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/hqb/audit-result/index"));
    });
  },
  meta: {
    title: "审核通过",
    keepAlive: false
  },
  hidden: false
}, {
  name: "cz-detail",
  path: "/cz/detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/cz/detail/index"));
    });
  },
  meta: {
    title: "视频医生云闪付专享特惠",
    keepAlive: false
  },
  hidden: false
}, {
  name: "kfsp-index",
  path: "/kfsp/index",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/kfsp/index/index"));
    });
  },
  meta: {
    title: "远程视频康护咨询",
    keepAlive: false
  },
  hidden: false
}, {
  name: "kfsp-appoint",
  path: "/kfsp/appoint",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/kfsp/appoint/index"));
    });
  },
  meta: {
    title: "选择时间",
    keepAlive: false
  },
  hidden: false
}, {
  name: "kfsp-register",
  path: "/kfsp/register",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/kfsp/register/index"));
    });
  },
  meta: {
    title: "提交材料",
    keepAlive: false
  },
  hidden: false
}, {
  name: "kfsp-audit-fail",
  path: "/kfsp/audit-fail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/kfsp/audit-result/index"));
    });
  },
  meta: {
    title: "详情",
    keepAlive: false
  },
  hidden: false
}, {
  name: "kfsp-audit-pending",
  path: "/kfsp/audit-pending",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/kfsp/audit-result/index"));
    });
  },
  meta: {
    title: "详情",
    keepAlive: false
  },
  hidden: false
}, {
  name: "kfsp-report-detail",
  path: "/kfsp/report-detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/kfsp/report-detail/index"));
    });
  },
  meta: {
    title: "咨询报告",
    keepAlive: false
  },
  hidden: false
}, {
  name: "kfsp-doctor-detail",
  path: "/kfsp/doctor-detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/kfsp/doctor-detail/index"));
    });
  },
  meta: {
    title: "选择康复师",
    keepAlive: false
  },
  hidden: false
}, {
  name: "kfsp-comment",
  path: "/kfsp/comment",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/kfsp/comment/index"));
    });
  },
  meta: {
    title: "服务评价",
    keepAlive: false
  },
  hidden: false
}, {
  name: "rightsList",
  path: "/nursing/rights-list",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/nursing/rightsList"));
    });
  },
  meta: {
    title: "我的权益",
    keepAlive: false
  },
  hidden: false
}, {
  name: "rightsType",
  path: "/nursing/rightsType",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/nursing/rightsType"));
    });
  },
  meta: {
    title: "权益详情",
    keepAlive: false
  },
  hidden: false
}, (_ref = {
  name: 'nursing-order-detail',
  path: '/nursing/order-detail',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/nursing/order-detail/index'));
    });
  },
  meta: {
    title: '订单详情',
    keepAlive: false
  }
}, _defineProperty(_ref, "name", "nursing-order-detail"), _defineProperty(_ref, "path", "/nursing/order-detail"), _defineProperty(_ref, "component", function component() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require("@/views/nursing/order-detail/index"));
  });
}), _defineProperty(_ref, "meta", {
  title: "订单详情",
  keepAlive: false
}), _defineProperty(_ref, "hidden", false), _ref), {
  name: "serviceDetail",
  path: "/nursing/service-detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/nursing/serviceDetail"));
    });
  },
  meta: {
    title: "服务详情",
    keepAlive: false
  },
  hidden: false
}, {
  name: "completeInfo",
  path: "/nursing/complete-info",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/nursing/completeInfo"));
    });
  },
  meta: {
    title: "完善信息",
    keepAlive: false
  },
  hidden: false
}, {
  name: "Transfer",
  path: "/transfer",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/transfer/index/index"));
    });
  },
  meta: {
    title: "我的权益",
    keepAlive: true
  },
  hidden: false
}, {
  name: "transfer-register",
  path: "/transfer/register",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/transfer/register/index"));
    });
  },
  meta: {
    title: "权益申请",
    keepAlive: false
  },
  hidden: false
}, {
  name: "transfer-audit-pending",
  path: "/transfer/audit-pending",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/transfer/audit-result/index"));
    });
  },
  meta: {
    title: "审核中",
    keepAlive: false
  },
  hidden: false
}, {
  name: "transfer-audit-fail",
  path: "/transfer/audit-fail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/transfer/audit-result/index"));
    });
  },
  meta: {
    title: "审核不通过",
    keepAlive: false
  },
  hidden: false
}, {
  name: "transfer-audit-success",
  path: "/transfer/audit-success",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/transfer/audit-result/index"));
    });
  },
  meta: {
    title: "审核通过",
    keepAlive: false
  },
  hidden: false
}, {
  name: "gene-test-index",
  path: "/gene-test/index",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/gene-test/index/index"));
    });
  },
  meta: {
    title: "基因检测",
    keepAlive: false
  },
  hidden: false
}, {
  name: "gene-test-detail",
  path: "/gene-test/detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/gene-test/detail/index"));
    });
  },
  meta: {
    title: "产品详情  ",
    keepAlive: false
  },
  hidden: false
}, {
  name: "gene-test-information",
  path: "/gene-test/information",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/gene-test/information/index"));
    });
  },
  meta: {
    title: "基因检测",
    keepAlive: false
  },
  hidden: false
}, {
  name: "gene-test-report",
  path: "/gene-test/report",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/gene-test/report/index"));
    });
  },
  meta: {
    title: "查看报告",
    keepAlive: false
  },
  hidden: false
}, {
  name: "gene-test-preview",
  path: "/gene-test/preview",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/gene-test/preview/index"));
    });
  },
  meta: {
    title: "报告内容",
    keepAlive: false
  },
  hidden: false
}, {
  name: "gene-test-process",
  path: "/gene-test/process",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/gene-test/process/index"));
    });
  },
  meta: {
    title: "操作流程",
    keepAlive: false
  },
  hidden: false
}, {
  name: "GreenRoad",
  path: "/staticPage/greenroad",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staticPage/greenRoad"));
    });
  },
  meta: {
    title: "重疾绿色通道",
    keepAlive: false
  },
  hidden: false
}, {
  name: "Operation",
  path: "/staticPage/operation",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/staticPage/operation"));
    });
  },
  meta: {
    title: "重疾住院手术安排",
    keepAlive: false
  },
  hidden: false
}, {
  name: "dental-detail",
  path: "/dental/detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/dental/detail/index"));
    });
  },
  meta: {
    title: "全家3人标准十项口腔检查",
    keepAlive: false
  },
  hidden: false
}, {
  name: "dental-activate-code",
  path: "/dental/activate-code",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/dental/activate-code/index"));
    });
  },
  meta: {
    title: "全家3人标准十项口腔检查",
    keepAlive: false
  },
  hidden: false
}, {
  name: "home-self-checking-detail",
  path: "/home-self-checking/detail",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/home-self-checking/detail/index"));
    });
  },
  meta: {
    title: "幽门螺旋杆菌、便隐血双检",
    keepAlive: false
  },
  hidden: false
}, {
  name: "home-self-checking-complete-info",
  path: "/home-self-checking/complete-info",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/home-self-checking/completeInfo"));
    });
  },
  meta: {
    title: "幽门螺旋杆菌、便隐血双检",
    keepAlive: false
  },
  hidden: false
}, {
  name: "home-self-form-info",
  path: "/home-self-checking/self-checking-info",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/home-self-checking/selfCheckingInfo"));
    });
  },
  meta: {
    title: "幽门螺旋杆菌、便隐血双检",
    keepAlive: false
  },
  hidden: false
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/404"));
    });
  },
  hidden: true
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export var asyncRoutes = [// 404 page must be placed at the end !!!
{
  path: "*",
  redirect: "/404",
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;