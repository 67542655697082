var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "0.32rem 0.24rem 1.8rem 0.24rem" } },
    [
      _c(
        "div",
        { staticClass: "info-tip", staticStyle: { "margin-top": "0.2rem" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("请使用登记时的手机号登录使用本服务"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "register-bt", on: { click: _vm.goRegister } },
              [_vm._v("立刻登记")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [_vm._v("被保人手机号")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.phoneNumber,
            expression: "phoneNumber",
          },
        ],
        staticClass: "input",
        attrs: { type: "text", placeholder: "请输入" },
        domProps: { value: _vm.phoneNumber },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.phoneNumber = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [_vm._v("验证码")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex", staticStyle: { position: "relative" } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.verificationCode,
                expression: "verificationCode",
              },
            ],
            staticClass: "input",
            attrs: { type: "text", placeholder: "请输入" },
            domProps: { value: _vm.verificationCode },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.verificationCode = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "code flex", on: { click: _vm.sendCode } },
            [_vm._v(_vm._s(_vm.sending ? _vm.timer : "获取验证码"))]
          ),
        ]
      ),
      _vm._v(" "),
      _c("button", { staticClass: "login-bt flex", on: { click: _vm.login } }, [
        _vm._v("登录"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }