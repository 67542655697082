var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "completeInfo" },
    [
      _c(
        "div",
        { staticClass: "rightsInfo" },
        [
          _c("div", { staticClass: "serviceContent" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contentInfo", on: { click: _vm.goDetail } },
              [
                _c("div", { staticClass: "itemInfoLeft" }, [
                  _c("img", { attrs: { src: _vm.getImage(_vm.iconUrl) } }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "itemInfoRight" }, [
                  _c("div", { staticClass: "rightsTitle" }, [
                    _vm._v(
                      "\n              " + _vm._s(_vm.title) + "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "itemInfoRightBottom" }, [
                    _c("div", { staticClass: "rightsPrice" }, [
                      _c("div", { staticClass: "price" }, [
                        _vm._v(
                          "\n                  ¥" +
                            _vm._s(_vm.originalPrice) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._m(1),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "serviceInfo" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "infoForm" }, [
              _c("div", { staticClass: "name formItem" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v("\n              姓名\n            "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "\n              " + _vm._s(_vm.userName) + "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "phone formItem" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v("\n              手机号\n            "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    "\n              " + _vm._s(_vm.phone) + "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "idType formItem" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v("\n              证件类型\n            "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "value",
                    on: {
                      click: function ($event) {
                        return _vm.openSwitch("idTypeVisible")
                      },
                    },
                  },
                  [
                    !_vm.idType
                      ? _c("div", { staticClass: "placeholder" }, [
                          _vm._v(
                            "\n                请选择证件类型\n              "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.idTypeName) +
                              "\n              "
                          ),
                        ]),
                    _vm._v(" "),
                    _vm._m(3),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "idCard formItem" },
                [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("\n                证件号\n            "),
                  ]),
                  _vm._v(" "),
                  _c("nut-textinput", {
                    attrs: {
                      placeholder: "请输入内容",
                      "has-border": false,
                      "clear-btn": false,
                    },
                    model: {
                      value: _vm.idCard,
                      callback: function ($$v) {
                        _vm.idCard = $$v
                      },
                      expression: "idCard",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "bodyStatus formItem" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v("\n              性别\n            "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "value",
                    on: {
                      click: function ($event) {
                        return _vm.openSwitch("sexVisible")
                      },
                    },
                  },
                  [
                    !_vm.sex
                      ? _c("div", { staticClass: "placeholder" }, [
                          _vm._v(
                            "\n                请选择性别\n              "
                          ),
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.sexName) +
                              "\n              "
                          ),
                        ]),
                    _vm._v(" "),
                    _vm._m(4),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.orderType != "PSYCHOLOGICAL_COUNSELING"
                ? _c("div", { staticClass: "bodyStatus formItem" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("\n              身体状态\n            "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            return _vm.openSwitch("bodyStatusVisible")
                          },
                        },
                      },
                      [
                        !_vm.bodyStatus
                          ? _c("div", { staticClass: "placeholder" }, [
                              _vm._v(
                                "\n                请选择身体状态\n              "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.bodyStatusName) +
                                  "\n              "
                              ),
                            ]),
                        _vm._v(" "),
                        _vm._m(5),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderType != "PSYCHOLOGICAL_COUNSELING"
                ? _c("div", { staticClass: "serviceTime formItem" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.fuwushijian) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            _vm.timePickerVisible = true
                          },
                        },
                      },
                      [
                        !_vm.serviceTime
                          ? _c("div", { staticClass: "placeholder" }, [
                              _vm._v(
                                "\n                请选择服务时间\n              "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.serviceTime) +
                                  "\n              "
                              ),
                            ]),
                        _vm._v(" "),
                        _vm._m(6),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderType != "PSYCHOLOGICAL_COUNSELING"
                ? _c("div", { staticClass: "area formItem" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("\n              选择地区\n            "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "value",
                        on: {
                          click: function ($event) {
                            _vm.showAddress = true
                          },
                        },
                      },
                      [
                        !_vm.area
                          ? _c("div", { staticClass: "placeholder" }, [
                              _vm._v(
                                "\n                省、市、区、街道\n              "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.area) +
                                  "\n              "
                              ),
                            ]),
                        _vm._v(" "),
                        _vm._m(7),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderType != "PSYCHOLOGICAL_COUNSELING"
                ? _c("div", { staticClass: "address formItem" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("\n              详细地址\n            "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            placeholder: "请输入详细地址",
                            maxlength: "200",
                          },
                          model: {
                            value: _vm.address,
                            callback: function ($$v) {
                              _vm.address = $$v
                            },
                            expression: "address",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderType != "LEAVE_HOSPITAL_TRAFFIC_ARRANGE" &&
              _vm.orderType != "SEE_A_DOCTOR" &&
              _vm.orderType != "PSYCHOLOGICAL_COUNSELING"
                ? _c("div", { staticClass: "addressTip" }, [
                    _vm._v(
                      "\n            如果是院内服务，请填写所在医院、楼号、楼层、科室、病房、床位号\n          "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderType != "LEAVE_HOSPITAL_TRAFFIC_ARRANGE" &&
              _vm.orderType != "SEE_A_DOCTOR" &&
              _vm.orderType != "PSYCHOLOGICAL_COUNSELING"
                ? _c("div", { staticClass: "file formItem" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("\n              证明材料\n            "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "value fileItem" },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "uploadImg",
                            attrs: {
                              "before-upload": _vm.beforeImgUpload,
                              "on-success": _vm.uploadImgSuccess,
                              "on-remove": _vm.uploadImgRemove,
                              action: _vm.url,
                              "list-type": "picture-card",
                              multiple: "",
                              "file-list": _vm.fileList,
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/icon_image.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.orderType != "LEAVE_HOSPITAL_TRAFFIC_ARRANGE" &&
              _vm.orderType != "SEE_A_DOCTOR" &&
              _vm.orderType != "PSYCHOLOGICAL_COUNSELING"
                ? _c("div", { staticClass: "addressTip" }, [
                    _vm._v(
                      "\n            入院通知单或者出院小结等相关证明材料\n          "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "comment formItem" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v("\n              备注\n            "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "value" },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 3,
                        placeholder: "请输入备注信息",
                        maxlength: "200",
                      },
                      model: {
                        value: _vm.comment,
                        callback: function ($$v) {
                          _vm.comment = $$v
                        },
                        expression: "comment",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "submitOrder",
              attrs: { loading: _vm.loading, type: "primary", round: "" },
              on: { click: _vm.submitOrder },
            },
            [_vm._v("提交订单")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "fileInfoContent" }, [
            _vm._v("\n        1）二级以上公立医院出具的诊断书"),
            _c("br"),
            _vm._v("\n        2）二级以上公立医院出具的相关医学检查报告单"),
            _c("br"),
            _vm._v("\n        3）出院小结（若有请提供）\n      "),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("nut-picker", {
        attrs: { "is-visible": _vm.idTypeVisible, "list-data": _vm.idTypeList },
        on: {
          close: function ($event) {
            return _vm.closeSwitch("idTypeVisible")
          },
          confirm: _vm.idTypeConfirm,
        },
      }),
      _vm._v(" "),
      _c("nut-picker", {
        attrs: { "is-visible": _vm.sexVisible, "list-data": _vm.sexList },
        on: {
          close: function ($event) {
            return _vm.closeSwitch("sexVisible")
          },
          confirm: _vm.sexConfirm,
        },
      }),
      _vm._v(" "),
      _c("nut-picker", {
        attrs: {
          "is-visible": _vm.bodyStatusVisible,
          "list-data": _vm.bodyStatusList,
        },
        on: {
          close: function ($event) {
            return _vm.closeSwitch("bodyStatusVisible")
          },
          confirm: _vm.confirm,
        },
      }),
      _vm._v(" "),
      _c("nut-datepicker", {
        attrs: {
          "is-visible": _vm.timePickerVisible,
          "default-value": _vm.serviceTime,
          type: "datetime",
          "start-date": _vm.startDate,
          "end-date": "2050-01-01 00:00",
          "minute-step": 30,
        },
        on: {
          close: function ($event) {
            return _vm.closeSwitch("timePickerVisible")
          },
          choose: _vm.setServiceTime,
        },
      }),
      _vm._v(" "),
      _c("nut-address", {
        attrs: {
          province: _vm.province,
          city: _vm.city,
          country: _vm.country,
          town: _vm.town,
          "custom-address-title": "请选择所在地区",
        },
        on: { onChange: _vm.onChange1, close: _vm.close1 },
        model: {
          value: _vm.showAddress,
          callback: function ($$v) {
            _vm.showAddress = $$v
          },
          expression: "showAddress",
        },
      }),
      _vm._v(" "),
      _c(
        "nut-actionsheet",
        {
          attrs: {
            "is-visible": _vm.isVisible,
            "menu-items": _vm.menuItems,
            "choose-tag-value": "确定",
            "cancel-txt": "取消",
          },
          on: { close: _vm.switchActionSheet, choose: _vm.makeSureSubmit },
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _c("label", [_vm._v("提交后不可修改， 是否确认提交？")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blockTop" }, [
      _c("div", { staticClass: "titleLeft" }),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n            权益信息\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rightsNum" }, [
      _vm._v("\n                X "),
      _c("span", [_vm._v("1")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blockTop" }, [
      _c("div", { staticClass: "titleLeft" }),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n            服务信息\n          "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tip" }, [
        _vm._v("\n            请输入以下所有信息\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chooseIcon" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chooseIcon" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chooseIcon" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chooseIcon" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chooseIcon" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }