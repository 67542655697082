import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API.replace("center/api", "api/v2"),
      imgUrl: null,
      sending: false,
      timer: 60,
      name: null,
      idCardNo: null,
      mobile: null,
      verificationCode: null,
      reportImages: [],
      idCardImages: [],
      clientId: null,
      projectId: null,
      ownershipId: null
    };
  },
  created: function created() {
    var _this = this;

    console.log(this.$route);
    console.log(this.isHqb() + "," + this.isYnhl());
    this.initCommonParam();
    console.log(this.clientId + "," + this.projectId + "," + this.ownershipId);
    request({
      url: "".concat(this.baseUrl, "user/get-by-token")
    }).then(function (res) {
      if (res.status === 200) {
        _this.name = res.data.data.name;
        _this.mobile = res.data.data.mobile;
      }
    });
  },
  computed: {
    imgUploadUrl: function imgUploadUrl() {
      return process.env.VUE_APP_BASE_FILE + 'upload';
    },
    images: function images() {
      return this.reportImages.concat({
        id: -1,
        src: "/static/images/ic_capture_add.png"
      });
    },
    IdImages: function IdImages() {
      return this.idCardImages.concat({
        id: -1,
        src: "/static/images/ic_capture_add.png"
      });
    },
    idCardImgsList: function idCardImgsList() {
      var imgUrl = this.imgUrl;

      if (isNull(this.idCardImages)) {
        return [];
      }

      var list = [];
      this.idCardImages.forEach(function (item) {
        list.push({
          url: getImage(item),
          uuid: item
        });
      });
      return list;
    },
    reportImgsList: function reportImgsList() {
      var imgUrl = this.imgUrl;

      if (isNull(this.reportImages)) {
        return [];
      }

      var list = [];
      this.reportImages.forEach(function (item) {
        list.push({
          url: getImage(item),
          uuid: item
        });
      });
      return list;
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    __getMobileCode: function __getMobileCode() {
      request({
        url: 'verification/get-mobile-code',
        method: 'get',
        params: {
          mobile: this.mobile
        }
      }).then(function (res) {});
    },
    // goLogin() {
    //   this.$router.push({
    //     path: '/hqb/login'
    //   })
    // },
    check: function check() {
      if (isNull(this.name)) {
        return '请输入被保人姓名';
      }

      if (isNull(this.idCardNo)) {
        return '请输入被保人身份证';
      }

      if (this.idCardNo.length != 18) {
        return '身份证应为18位';
      }

      if (isNull(this.mobile)) {
        return '请输入被保人手机号';
      }

      if (!/^\d{11}$/img.test(this.mobile)) {
        return '手机号应为11位数字';
      } //if (isNull(this.verificationCode)) {
      //  return '请输入验证码'
      //}


      if (isNull(this.reportImages)) {
        return '请上传病理报告&出院小结/住院报告';
      }

      if (isNull(this.idCardImages)) {
        return '请上传身份证照片';
      }
    },
    submit: function submit() {
      var _this2 = this;

      var warn = this.check();

      if (!isNull(warn)) {
        this.$message.error(warn);
        return;
      }

      request({
        url: 'ownership/audit/submit',
        method: 'post',
        data: {
          clientId: parseInt(this.clientId),
          projectId: parseInt(this.projectId),
          ownershipId: parseInt(this.ownershipId),
          mobile: this.mobile,
          idCardNo: this.idCardNo,
          username: this.name,
          idCardImages: this.idCardImages,
          reportImages: this.reportImages // verificationCode: this.verificationCode

        }
      }).then(function (res) {
        //console.log(res)
        if (res.status === 200 && res.data.status == 0) {
          _this2.$router.push({
            path: '/transfer/audit-pending'
          });
        } else if (res.data && res.data.errorMessage) {
          _this2.$message.error(res.data.errorMessage);
        }
      });
    },
    __time: function __time() {
      if (this.timer - 1 <= 0) {
        this.sending = false;
        this.timer = 60;
        return;
      }

      this.timer--;
      setTimeout(this.__time, 1000);
    },
    // sendCode() {
    //   if (isNull(this.mobile)) {
    //     this.$message.error("手机号不能为空")
    //     return
    //   }
    //   if (this.sending) {
    //     return
    //   }
    //   this.__getMobileCode()
    //   this.sending = true
    //   this.timer = 60
    //   setTimeout(this.__time, 1000)
    // },
    beforeImgUpload: function beforeImgUpload(file) {
      var fileFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
      var limitSize = true;

      if (file.size > 20 * 1024 * 1024) {
        limitSize = false;
      }

      if (!limitSize) {
        this.$message.error('上传图片大小不能超过20M!');
      }

      if (!fileFormat) {
        this.$message.error('上传图片只能是 JPEG、JPG、PNG格式!');
      }

      return fileFormat && limitSize;
    },
    uploadReportImgSuccess: function uploadReportImgSuccess() {
      this.uploadImgSuccess.call(this, arguments[0], arguments[1], arguments[2], this.reportImages);
    },
    uploadIdCardImgSuccess: function uploadIdCardImgSuccess() {
      this.uploadImgSuccess.call(this, arguments[0], arguments[1], arguments[2], this.idCardImages);
    },
    uploadImgSuccess: function uploadImgSuccess(response, file, fileList, imgs) {
      console.log(arguments);

      if (isNull(response)) {
        return;
      }

      var uuid = response.data.uuid;
      file.uuid = uuid;
      imgs.push(uuid);
    },
    uploadReportImgRemove: function uploadReportImgRemove() {
      this.uploadImgRemove.call(this, arguments[0], arguments[1], this.idCardImages);
    },
    uploadIdCardImgRemove: function uploadIdCardImgRemove() {
      this.uploadImgRemove.call(this, arguments[0], arguments[1], this.reportImages);
    },
    uploadImgRemove: function uploadImgRemove(file, fileList, imgs) {
      console.log(arguments);
      imgs.remove(file.uuid);
    }
  }
};