import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.string.sub";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import { getTomorrowString } from '@/utils/index';
var uploadUrl = process.env.VUE_APP_BASE_FILE + 'upload';
export default {
  components: {},
  data: function data() {
    return {
      loading: false,
      menuItems: [{
        'name': '确定'
      }],
      isVisible: false,
      // baseUrl: process.env.VUE_APP_BASE_API.replace("0/center/api", "1/api/v2"),
      baseUrl: process.env.VUE_APP_BASE_API.replace("center/api", "api/v2"),
      drawer: false,
      dialogVisible: false,
      bodyStatus: null,
      bodyStatusName: null,
      serviceTime: null,
      area: null,
      address: null,
      file: null,
      comment: null,
      idCard: null,
      // 证件类型
      idType: null,
      idTypeName: null,
      idTypeVisible: false,
      idTypeList: [[{
        label: 1,
        value: '身份证'
      }, {
        label: 2,
        value: '护照'
      }, {
        label: 3,
        value: '军官证'
      }, {
        label: 4,
        value: '港澳台通行证'
      }, {
        label: 5,
        value: '台湾通行证'
      }, {
        label: 6,
        value: '外国人居留证'
      }, {
        label: 7,
        value: '其他'
      }]],
      //性别
      sex: null,
      sexName: null,
      sexVisible: false,
      sexList: [[{
        label: "1",
        value: '男'
      }, {
        label: "2",
        value: '女'
      }]],
      bodyStatusVisible: false,
      bodyStatusList: [[{
        label: 1,
        value: '可行动'
      }, {
        label: 2,
        value: '半失能'
      }, {
        label: 3,
        value: '失能'
      }]],
      timePickerVisible: false,
      url: uploadUrl,
      fileList: [],
      materialImages: [],
      showAddress: false,
      province: [],
      // 省
      city: [],
      // 市
      country: [],
      // 县
      town: [],
      cityCode: '',
      date: '',
      start: '',
      allArea: [],
      title: '',
      iconUrl: '',
      originalPrice: '',
      userName: '',
      phone: '',
      startDate: '',
      orderType: '',
      fuwushijian: "服务时间"
    };
  },
  created: function created() {
    var _this2 = this;

    request({
      url: "".concat(this.baseUrl, "china-area-code/get")
    }).then(function (res) {
      if (res.status === 200) {
        _this2.allArea = res.data.data;
        _this2.province = _this2.addId(res.data.data);
      }
    });
    request({
      url: "".concat(this.baseUrl, "user/get-by-token")
    }).then(function (res) {
      if (res.status === 200) {
        _this2.userName = res.data.data.name;
        _this2.phone = res.data.data.mobile;
      }
    });
    var _this$$route$query = this.$route.query,
        title = _this$$route$query.title,
        originalPrice = _this$$route$query.originalPrice,
        orderType = _this$$route$query.orderType,
        iconUrl = _this$$route$query.iconUrl;
    this.orderType = orderType;

    if (this.orderType === 'LEAVE_HOSPITAL_TRAFFIC_ARRANGE') {
      this.fuwushijian = "出院时间";
    }

    this.title = title;
    this.iconUrl = sessionStorage.getItem('appointmentProjectImg');
    this.originalPrice = originalPrice;
    this.startDate = getTomorrowString() + ' 00:00';

    if (this.iconUrl === null || this.iconUrl === '' || this.iconUrl === undefined) {
      this.iconUrl = iconUrl;
    }
  },
  methods: {
    makeSureSubmit: function makeSureSubmit(itemParams) {
      console.log(itemParams);

      if (itemParams.name === '确定') {
        this.requestOrder();
      }
    },
    goDetail: function goDetail() {
      var _this = this;

      if (this.orderType === 'LEAVE_HOSPITAL_TRAFFIC_ARRANGE' || this.orderType === 'SEE_A_DOCTOR' || this.orderType === 'PSYCHOLOGICAL_COUNSELING') {
        this.$router.push({
          path: "/nursing/rightsType?title=".concat(_this.title, "&from=order&orderType=").concat(this.orderType)
        });
      } else {
        this.$router.push({
          path: "/nursing/service-detail?title=".concat(_this.title, "&from=order")
        });
      }
    },
    addId: function addId(list) {
      return list.map(function (item) {
        item.id = item.code;
        return item;
      });
    },
    onChange1: function onChange1(cal) {
      // 判断下一个行政区列表的内容是否有值
      if (cal.custom === 'province') {
        this.city = this.addId(cal.value.sub);
      }

      if (cal.custom === 'city') {
        this.country = this.addId(cal.value.sub);
      }

      if (cal.custom === 'country') {
        this.town = this.addId(cal.value.sub);
      }

      if (!cal.value.sub || cal.value.sub.length < 1) {
        this.showAddress = false;
      }
    },
    close1: function close1(val) {
      this.area = val.data.addressStr;
      this.cityCode = val.data.town.code;
    },
    beforeImgUpload: function beforeImgUpload(file) {
      var fileFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
      var limitSize = true; // if (file.size > 20 * 1024 * 1024) {
      //   limitSize = false
      // }
      // if (!limitSize) {
      //   this.$message.error('上传图片大小不能超过5M!')
      // }

      if (!fileFormat) {
        this.$message.error('上传图片只能是 JPG、PNG格式!');
      }

      return fileFormat && limitSize;
    },
    uploadImgSuccess: function uploadImgSuccess(response, file, fileList, imgs) {
      if (!response) {
        return;
      }

      var uuid = response.data.uuid;
      file.uuid = uuid;
      this.materialImages.push(uuid);
    },
    uploadImgRemove: function uploadImgRemove(file, fileList, imgs) {
      this.materialImages.remove(file.uuid);
    },
    openSwitch: function openSwitch(param) {
      this["".concat(param)] = true;
    },
    closeSwitch: function closeSwitch(param) {
      this["".concat(param)] = false;
    },
    idTypeConfirm: function idTypeConfirm(chooseData) {
      this.idTypeName = chooseData[0].value;
      this.idType = chooseData[0].label;
      this.closeSwitch('idTypeVisible');
    },
    sexConfirm: function sexConfirm(chooseData) {
      this.sexName = chooseData[0].value;
      this.sex = chooseData[0].label;
      this.closeSwitch('sexVisible');
    },
    confirm: function confirm(chooseData) {
      this.bodyStatusName = chooseData[0].value;
      this.bodyStatus = chooseData[0].label;
      this.closeSwitch('bodyStatusVisible');
    },
    setServiceTime: function setServiceTime(param) {
      this.serviceTime = param[5];
      var timeArr = param[5].split(' ');
      this.date = timeArr[0];
      this.start = timeArr[1];
    },
    switchActionSheet: function switchActionSheet() {
      this.isVisible = !this.isVisible;
    },
    submitOrder: function submitOrder() {
      var fieldsList = [{
        name: '证件类型',
        value: this.idType
      }, {
        name: '证件号',
        value: this.idCard
      }, {
        name: '性别',
        value: this.sex
      }]; //共用页面整理

      if (this.orderType === 'LEAVE_HOSPITAL_TRAFFIC_ARRANGE') {
        var a = [{
          name: '身体状态',
          value: this.bodyStatus
        }, {
          name: '出院时间',
          value: this.start
        }, {
          name: '选择地区',
          value: this.cityCode
        }, {
          name: '详细地址',
          value: this.address
        }];
        fieldsList.push.apply(fieldsList, a);
      } else if (this.orderType === 'SEE_A_DOCTOR') {
        var _a = [{
          name: '身体状态',
          value: this.bodyStatus
        }, {
          name: '服务时间',
          value: this.start
        }, {
          name: '选择地区',
          value: this.cityCode
        }, {
          name: '详细地址',
          value: this.address
        }];
        fieldsList.push.apply(fieldsList, _a);
      } else if (this.orderType === 'PSYCHOLOGICAL_COUNSELING') {} else {
        var _a2 = [{
          name: '身体状态',
          value: this.bodyStatus
        }, {
          name: '服务时间',
          value: this.start
        }, {
          name: '选择地区',
          value: this.cityCode
        }, {
          name: '详细地址',
          value: this.address
        }, {
          name: '证明材料',
          value: this.materialImages
        }];
        fieldsList.push.apply(fieldsList, _a2);
      }

      console.log(fieldsList);
      var infoError = false;

      for (var _i = 0, _fieldsList = fieldsList; _i < _fieldsList.length; _i++) {
        var item = _fieldsList[_i];

        if (Array.isArray(item.value)) {
          if (item.value.length === 0) {
            this.$notify.text("".concat(item.name, "\u4E0D\u80FD\u4E3A\u7A7A"), {
              duration: 2000
            });
            infoError = true;
            break;
          }
        } else {
          if (!item.value) {
            this.$notify.text("".concat(item.name, "\u4E0D\u80FD\u4E3A\u7A7A"), {
              duration: 2000
            });
            infoError = true;
            break;
          }
        }
      } // fieldsList.forEach(item=>{
      //   if(Array.isArray(item.value)){
      //     if(item.value.length===0){
      //       this.$notify.text(`${item.name}不能为空`, {
      //         duration: 2000
      //       });
      //       infoError=true
      //     }
      //   }else{
      //     if(!item.value){
      //       this.$notify.text(`${item.name}不能为空`, {
      //         duration: 2000
      //       });
      //       infoError=true
      //     }
      //   }
      // })


      if (infoError) {
        return;
      }

      this.switchActionSheet();
    },
    requestOrder: function requestOrder() {
      var _this3 = this;

      var _this$$route$query2 = this.$route.query,
          clientId = _this$$route$query2.clientId,
          projectId = _this$$route$query2.projectId,
          ownershipId = _this$$route$query2.ownershipId,
          userOwnershipId = _this$$route$query2.userOwnershipId;
      var type = 'ONLINE_NURSING_APPOINTMENT';

      if (this.orderType && (this.orderType === 'LEAVE_HOSPITAL_TRAFFIC_ARRANGE' || this.orderType === 'SEE_A_DOCTOR' || this.orderType === 'PSYCHOLOGICAL_COUNSELING')) {
        type = this.orderType;
      }

      this.loading = true;
      var data = {
        clientId: clientId,
        projectId: projectId,
        ownershipId: ownershipId,
        userOwnershipId: userOwnershipId,
        type: type,
        idType: this.idType,
        idCard: this.idCard,
        sex: this.sex,
        comment: this.comment
      };
      console.log("asdasd" + this.idType); //共用页面整理

      if (this.orderType === 'LEAVE_HOSPITAL_TRAFFIC_ARRANGE') {
        data["bodyStatus"] = this.bodyStatus;
        data["date"] = this.date;
        data["start"] = this.start;
        data["cityCode"] = this.cityCode;
        data["address"] = this.address;
      } else if (this.orderType === 'SEE_A_DOCTOR') {
        data["bodyStatus"] = this.bodyStatus;
        data["date"] = this.date;
        data["start"] = this.start;
        data["cityCode"] = this.cityCode;
        data["address"] = this.address;
      } else if (this.orderType === 'PSYCHOLOGICAL_COUNSELING') {//不添加
      } else {
        data["bodyStatus"] = this.bodyStatus;
        data["date"] = this.date;
        data["start"] = this.start;
        data["cityCode"] = this.cityCode;
        data["address"] = this.address;
        data["materialImages"] = this.materialImages;
      }

      console.log(data);
      request({
        url: 'nursing/appointment',
        method: 'post',
        data: data
      }).then(function (res) {
        if (res.status === 200) {
          _this3.loading = false;

          _this3.$dialog.closed();

          _this3.$router.push({
            path: "/nursing/order-detail?id=".concat(userOwnershipId, "&from=complete&type=").concat(type)
          });
        }
      }).catch(function (error) {
        _this3.loading = false;
      });
    }
  }
};