//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      title: '',
      buttonShow: true
    };
  },
  mounted: function mounted() {
    var _this$$route$query = this.$route.query,
        title = _this$$route$query.title,
        from = _this$$route$query.from;
    this.title = title;

    if (from && from === 'order') {
      this.buttonShow = false;
    }
  },
  methods: {
    goInfo: function goInfo() {
      var _this$$route$query2 = this.$route.query,
          title = _this$$route$query2.title,
          originalPrice = _this$$route$query2.originalPrice,
          clientId = _this$$route$query2.clientId,
          projectId = _this$$route$query2.projectId,
          ownershipId = _this$$route$query2.ownershipId,
          userOwnershipId = _this$$route$query2.userOwnershipId;
      this.$router.push({
        path: "/nursing/complete-info?title=".concat(title, "&originalPrice=").concat(originalPrice, "&clientId=").concat(clientId, "&projectId=").concat(projectId, "&ownershipId=").concat(ownershipId, "&userOwnershipId=").concat(userOwnershipId)
      });
    }
  }
};