var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "selfCheckingDetail" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _vm._m(1),
      _vm._v(" "),
      this.$route.query.clientId &&
      this.$route.query.projectId &&
      this.$route.query.ownershipGroupId &&
      this.$route.query.ownershipGroupId !== "undefined"
        ? _c("div", { staticClass: "footer" }, [
            _c(
              "div",
              { staticClass: "btn", on: { click: _vm.goInformation } },
              [_vm._v("\n        立即使用\n      ")]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "name" }, [
        _c("span", [_vm._v("幽门螺旋杆菌、便隐血双检")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n        服务内容\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        _vm._v("\n        这里是服务内容详情。。。\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }