//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Operation',
  data: function data() {
    return {
      isVisible: false,
      menuItems: [{
        'name': '确定'
      }]
    };
  },
  methods: {
    showPhoneTip: function showPhoneTip() {
      this.isVisible = true;
    },
    close: function close() {
      this.isVisible = false;
    }
  }
};