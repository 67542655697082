import "core-js/modules/es6.regexp.constructor";
import _typeof from "/var/jenkins_home/workspace/dashu-h5-test/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import "/var/jenkins_home/workspace/dashu-h5-test/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/jenkins_home/workspace/dashu-h5-test/node_modules/core-js/modules/es6.promise.js";
import "/var/jenkins_home/workspace/dashu-h5-test/node_modules/core-js/modules/es6.object.assign.js";
import "/var/jenkins_home/workspace/dashu-h5-test/node_modules/core-js/modules/es7.promise.finally.js";
Vue.config.devtools = true;
import Vue from "vue";
import Cookies from "js-cookie";
import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.scss"; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "@/icons"; // icon

import request from "@/utils/request";
import wx from "weixin-js-sdk";
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/nutui.css";
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

/**
 * 目前仅支持MOCK数据
 */

console.log(process.env.NODE_ENV);
console.log(process.env.VUE_APP_BASE_API);

try {
  ElementUI.Dialog.props.closeOnClickModal.default = false;
} catch (e) {}

try {
  ElementUI.MessageBox = function (msg) {
    arguments[0].confirm = function (confirm) {
      return function () {
        if (arguments[2].closeOnClickModal === false) {} else if (arguments[2].closeOnClickModal === true) {} else {
          //默认confirmcloseOnClickModal=false
          arguments[2].closeOnClickModal = false;
        }

        if (arguments[2].showClose === false) {} else if (arguments[2].showClose === true) {} else {
          //showClose=false
          arguments[2].showClose = false;
        }

        return confirm.apply(this, arguments);
      };
    }(arguments[0].confirm);

    return function () {
      msg.call(this, __options);
    };
  }(ElementUI.MessageBox);
} catch (e) {}

router.beforeEach(function (to, from, next) {
  if (to.path === "/transfer" || to.path === "/nursing/rights-list" || to.path === "/gene-test/index" || to.path === "/staticPage/greenroad" || to.path === "/staticPage/operation" || to.path === "/kfsp/index" || to.path === "/home-self-checking/detail" || to.path === "/dental/detail") {
    next();
  } else {
    if (to.query.__t) {
      next();
      return;
    }

    to.query.__t = new Date().getTime();
    next({
      path: to.path,
      query: to.query
    });
  }
});
router.afterEach(function (to, from) {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

if (process.env.NODE_ENV === "production") {}

Vue.use(ElementUI, {
  size: Cookies.get("size") || "small" // set element-ui default size

});
Vue.use(NutUI);
Vue.config.productionTip = false;
new Vue({
  el: "#app",
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});
Vue.config.ignoredElements = ["wx-open-launch-app", "wx-open-launch-weapp"];

Array.prototype.remove = function (elem) {
  var i = this.indexOf(elem);
  if (i >= 0) this.splice(i, 1);
  return this;
};

Array.prototype.insert = function (index, elem) {
  this.splice(index, 0, elem);
  return this;
};

Date.prototype.format = function (format) {
  if (!format) {
    format = "yyyy-MM-dd HH:mm:ss";
  }

  var date = this;

  var _year = date.getFullYear();

  var _month = date.getMonth() + 1;

  if (_month < 10) {
    _month = "0" + _month;
  }

  var _day = date.getDate();

  if (_day < 10) {
    _day = "0" + _day;
  }

  var _hours = date.getHours();

  if (_hours < 10) {
    _hours = "0" + _hours;
  }

  var _minutes = date.getMinutes();

  if (_minutes < 10) {
    _minutes = "0" + _minutes;
  }

  var _seconds = date.getSeconds();

  if (_seconds < 10) {
    _seconds = "0" + _seconds;
  }

  return format.replace("yyyy", _year).replace("MM", _month).replace("dd", _day).replace("HH", _hours).replace("mm", _minutes).replace("ss", _seconds);
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.getTime());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.add = function (hour, minute, second) {
  var ms = 0;

  if (hour) {
    ms += hour * 60 * 60 * 1000;
  }

  if (minute) {
    ms += minute * 60 * 1000;
  }

  if (second) {
    ms += second * 1000;
  }

  return new Date(new Date().getTime() + ms);
};

Date.prototype.diff = function (objDate2) {
  var v = "";
  var hour_range = 3600000;
  var minute_range = 60000;
  var start = this.getTime();
  var end = objDate2.getTime();
  var diff = Math.abs(end - start);
  var h = parseInt(diff / hour_range);
  var m = parseInt((diff - h * hour_range) / minute_range);
  var s = parseInt((diff - h * hour_range - m * minute_range) / 1000);

  if (h > 0) {
    v = h + "小时";
  }

  if (m > 0) {
    v += m + "分";
  }

  if (s > 0) {
    v += s + "秒";
  }

  if (isNull(v)) {
    v = "0秒";
  }

  return v;
};

function _getValue(v) {
  return v ? v : 0;
}

Vue.prototype.string2date = window.string2date = function (time) {
  /**
   *new Date('2019-11-10'),
   *此构造方法在IOS上有问题，所以做此处理
   */
  var arr = time.split(/[- :]/);

  var year = _getValue(arr[0]);

  var month = _getValue(arr[1]) - 1;

  var day = _getValue(arr[2]);

  var hour = _getValue(arr[3]);

  var minute = _getValue(arr[4]);

  var second = _getValue(arr[5]);

  var date = new Date(year, month, day, hour, minute, second);
  return date;
};

if (!window.Vue) {
  window.Vue = Vue;
}

window.isNull = function (value) {
  if (Array.isArray(value)) {
    return value.length == 0;
  }

  return typeof value === "undefined" || value === null || value === undefined || value === "";
};

Vue.prototype.isNull = window.isNull;

window.trim = function (value) {
  if (isNull(value)) {
    return "";
  }

  return value.replace(/^[ ]+|[ ]+$/gim, "");
};

Vue.prototype.trim = window.trim;

window.getImage = function (image) {
  if (!image) {
    return "";
  }

  image = image + "";

  if (image.indexOf("https:") > -1 || image.indexOf("http:") > -1) {
    return image;
  }

  var imageUrl = process.env.VUE_APP_BASE_FILE + "get/";
  return imageUrl + image;
};

Vue.prototype.getImage = window.getImage;

window.ab2string = function (arrayBuffer, onSuccess, onFail, thisArg) {
  var reader = new FileReader();

  reader.onload = function (event) {
    if (thisArg) {
      onSuccess.bind(thisArg)(decodeURIComponent(escape(event.target.result)));
    } else {
      onSuccess(decodeURIComponent(escape(event.target.result)));
    }
  };

  reader.onerror = function (event) {
    if (thisArg) {
      onFail.bind(thisArg)(event.target.error);
    } else {
      onFail(event.target.error);
    }
  };

  reader.readAsBinaryString(new Blob([arrayBuffer], {
    type: "application/octet-stream"
  }));
};

Vue.prototype.ab2string = window.ab2string;

String.prototype.format = String.prototype.format || function () {
  "use strict";

  var str = this.toString();

  if (arguments.length) {
    var t = _typeof(arguments[0]);

    var key;
    var args = "string" === t || "number" === t ? Array.prototype.slice.call(arguments) : arguments[0];

    for (key in args) {
      str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
    }
  }

  return str;
};

Vue.prototype.getClientId = function () {
  return this.$store.state.user.clientId;
};

Vue.prototype.getClientId = Vue.prototype.getClientId;

window.isNumber = function (value) {
  return /^[+-]?\d+$/gim.test(value);
};

Vue.prototype.isNumber = window.isNumber;

String.prototype.format = function () {
  var args = arguments;
  return this.replace(/\{(\d+)\}/g, function (m, i) {
    return args[i];
  });
};

window.getQuery = function (p) {
  return this.$route.query[p];
};

Vue.prototype.getQuery = window.getQuery;

Vue.prototype.initQuery = window.initQuery = function () {
  var clientId = this.getQuery("clientId");
  var projectId = this.getQuery("projectId");
  var ownershipId = this.getQuery("ownershipId");

  if (clientId) {
    window.clientId = Vue.prototype.clientId = clientId;
  }

  if (projectId) {
    window.projectId = Vue.prototype.projectId = projectId;
  }

  if (ownershipId) {
    window.ownershipId = Vue.prototype.ownershipId = ownershipId;
  }
};

(function (designWidth, maxDesignWidth) {
  var doc = document,
      docEl = doc.documentElement;
  var screenWidth = docEl.getBoundingClientRect().width;
  var width = Math.min(screenWidth, maxDesignWidth);
  var rem = width * 100 / designWidth;
  var html = document.documentElement;
  html.style.fontSize = rem + "px";
})(750, 750); //惠瞿保


window.isHqb = function () {
  return this.$route.path.indexOf("/hqb/") > -1;
};

Vue.prototype.isHqb = window.isHqb; //院内护理

window.isYnhl = function () {
  return this.$route.path.indexOf("/ynhl/") > -1;
};

Vue.prototype.isYnhl = window.isYnhl; //康复视频咨询

window.isKfsp = function () {
  return this.$route.path.indexOf("/kfsp/") > -1;
};

Vue.prototype.isKfsp = window.isKfsp;

Vue.prototype.initCommonParam = window.initCommonParam = function () {
  if (this.isHqb()) {
    Vue.prototype.clientId = window.clientId = 5;
    Vue.prototype.projectId = window.projectId = 3;
    Vue.prototype.ownershipId = window.ownershipId = 3;
  } else if (this.isYnhl()) {
    Vue.prototype.clientId = window.clientId = 5;
    Vue.prototype.projectId = window.projectId = 4;
    Vue.prototype.ownershipId = window.ownershipId = 13;
  } else if (this.isKfsp()) {
    Vue.prototype.clientId = window.clientId = 8;
    Vue.prototype.projectId = window.projectId = 2;
    Vue.prototype.ownershipId = window.ownershipId = 5;
  } else {
    this.clientId = this.getQuery("clientId");
    this.projectId = this.getQuery("projectId");
    this.ownershipId = this.getQuery("ownershipId");
  }
};

Vue.prototype.isDebug = window.isDebug = function () {
  try {
    return process.env.NODE_ENV != "production";
  } catch (e) {}

  return false;
};

Vue.prototype.wx = window.wx = wx;

Vue.prototype.wxConfig = window.wxConfig = function () {
  var url = window.location;
  request({
    url: "wechat-user/jsconfig?url=" + encodeURIComponent(url),
    method: "get"
  }).then(function (res) {
    if (res.status === 200) {
      //console.log(res)
      var data = res.data.data;
      wx.config({
        debug: false,
        // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: data.appId,
        // 必填，公众号的唯一标识
        timestamp: data.timestamp,
        // 必填，生成签名的时间戳
        nonceStr: data.nonceStr,
        // 必填，生成签名的随机串
        signature: data.signature,
        // 必填，签名
        jsApiList: ["startRecord", "stopRecord", "onVoiceRecordEnd", "playVoice", "pauseVoice", "stopVoice", "onVoicePlayEnd", "translateVoice", "onMenuShareTimeline", "wx-open-launch-weapp"],
        // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-launch-weapp"] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']

      });
      wx.ready(function () {
        //console.log('-----ready-----')
        //console.log(arguments)
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
        var btn = document.getElementById("launch-btn"); // launch 用户点击跳转按钮并对确认弹窗进行操作后触发

        if (btn) {
          btn.addEventListener("launch", function (e) {
            console.log("success");
          });
        } // error 用户点击跳转按钮后出现错误


        if (btn) {
          btn.addEventListener("error", function (e) {
            console.log("fail", e.detail);
          });
        }
      });
      wx.error(function (res) {
        console.log("-----error-----"); // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
      });
    }
  });
};