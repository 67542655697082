var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "codeDetail" }, [
    _c("div", { staticClass: "contentInfo" }, [
      _vm._v("\n    " + _vm._s(_vm.msg)),
      _c("br"),
      _c("br"),
      _vm._v(_vm._s(_vm.activateCode)),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _vm.activateCode != ""
        ? _c(
            "div",
            { staticClass: "button", on: { click: _vm.copyActivateCode } },
            [_vm._v("\n      复制激活码\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activateCode != ""
        ? _c("div", { staticClass: "tips" }, [
            _c("br"),
            _c("br"),
            _vm._v(
              "\n      提示：请复制激活码后前往【宇宙熊医生】公众号进行兑换预约使用。\n      "
            ),
            _c("br"),
            _c("br"),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }