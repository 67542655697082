//
//
//
//
//
//
//
// import request from '@/utils/request'
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      desc: null,
      desc1: "您提交的材料将于24小时内完成审核",
      desc2: "您的信息审核未通过，请重新上传提交",
      desc3: "您提交的材料已审核通过"
    };
  },
  created: function created() {
    if (this.isAuditPending()) {
      this.desc = this.desc1;
    } else if (this.isAuditFail()) {
      this.desc = this.desc2;
    } else if (this.isAuditSuccess()) {
      this.desc = this.desc3;
    }
  },
  computed: {
    auditFail: function auditFail() {
      return this.isAuditFail();
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    isAuditPending: function isAuditPending() {
      return this.$route.path.indexOf('/audit-pending') > -1;
    },
    isAuditFail: function isAuditFail() {
      return this.$route.path.indexOf('/audit-fail') > -1;
    },
    isAuditSuccess: function isAuditSuccess() {
      return this.$route.path.indexOf('/audit-success') > -1;
    },
    goRegister: function goRegister() {
      this.$router.push({
        path: '/hqb/register'
      });
    }
  }
};