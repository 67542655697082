var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
    },
    [
      _vm.show === 1
        ? _c("div", { staticClass: "gene-list" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("\n      请选择检测项目:\n    "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "gene-item",
                on: {
                  click: function ($event) {
                    return _vm.goDetail(1, 21)
                  },
                },
              },
              [
                _c("div", { staticClass: "name" }, [
                  _vm._v("\n        儿童安全用药抗感染类（8项）\n      "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    "\n        氟氯西林毒副作用、阿莫西林毒副作用、阿奇霉素疗效、红霉素代谢、醋酸泼尼···\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "btn" }, [
                  _vm._v("\n        查看详情\n      "),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "img",
                  attrs: { src: "/static/images/gene-test/yongyao.png" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "gene-item",
                on: {
                  click: function ($event) {
                    return _vm.goDetail(2, 22)
                  },
                },
              },
              [
                _c("div", { staticClass: "name" }, [
                  _vm._v("\n        儿童天赋（男/女 22项）\n      "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    "\n        社交焦虑障碍、求刺激倾向、新奇探索、毅力、责任心、绝对音高、专注能力···\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "btn" }, [
                  _vm._v("\n        查看详情\n      "),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "img",
                  attrs: { src: "/static/images/gene-test/tianfu.png" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "gene-item",
                on: {
                  click: function ($event) {
                    return _vm.goDetail(3, 23)
                  },
                },
              },
              [
                _c("div", { staticClass: "name" }, [
                  _vm._v("\n        儿童营养吸收（男/女 12项）\n      "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    "\n        维生素A需求、维生素B2需求、维生素B6需求、叶酸水平降低风险、维生素B12···\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "btn" }, [
                  _vm._v("\n        查看详情\n      "),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "img",
                  attrs: { src: "/static/images/gene-test/yingyang.png" },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.show === 2
        ? _c("div", [_c("Report", { attrs: { detail: _vm.reportData } })], 1)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }