//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import "./index.scss";
export default {
  name: 'StepDetail',
  props: {
    detail: {
      type: Object,
      default: {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit("onClose");
    }
  }
};