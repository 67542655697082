var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex",
      staticStyle: {
        "flex-direction": "column",
        height: "100%",
        "justify-content": "flex-start",
      },
    },
    [
      _c("img", {
        staticStyle: { width: "100%", height: "auto", "margin-top": "2rem" },
        attrs: { src: "/static/images/hqb/audit-result/bg.png" },
      }),
      _vm._v(" "),
      _vm.desc
        ? _c("div", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.desc))])
        : _vm._e(),
      _vm._v(" "),
      _vm.auditFail
        ? _c(
            "button",
            { staticClass: "bt flex", on: { click: _vm.goRegister } },
            [_vm._v("重新上传")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }