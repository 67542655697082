import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
import request from '@/utils/request';
import "./index.scss";
import { getToken, setToken } from "@/utils/auth";
export default {
  name: 'Transfer',
  data: function data() {
    return {
      clientId: 0,
      projectId: 0,
      ownershipId: 0,
      ownershipGroupId: 0,
      orderType: '',
      authorization: '',
      param: null,
      msg: '请稍等，页面加载中...'
    };
  },
  computed: {},
  created: function created() {
    console.log(this.$route, 'create'); //参数格式： clientId_projectId_ownershipId_ownershipGroupId_orderTypeId_预留1_预留2_预留3

    this.param = this.getQuery('param');

    if (this.param) {
      this.param = this.param.split('_');
      this.clientId = this.param[0];
      this.projectId = this.param[1];
      this.ownershipId = this.param[2];
      this.ownershipGroupId = this.param[3];
      var orderTypeId = this.param[4]; //console.log('parma33', this.param[4]);

      if (orderTypeId == 1) {
        //视频医生
        this.orderType = 'VIDEO_DOCTOR';
      } else if (orderTypeId == 2) {
        //在线护理预约
        this.orderType = 'ONLINE_NURSING_APPOINTMENT';
      } else if (orderTypeId == 3) {
        //远程视频康护咨询
        this.orderType = 'ONLINE_REHABILITATION_CONSULTANT';
      } else if (orderTypeId == 5) {
        //基因检测
        this.orderType = 'GENE_TESTING';
      } else if (orderTypeId == 10) {
        //齿科
        this.orderType = 'DENTAL_CHECKING';
      } else if (orderTypeId == 11) {
        //居家自检
        this.orderType = 'HOME_SELF_CHECKING';
      }
    } else {
      this.clientId = this.getQuery('clientId');
      this.projectId = this.getQuery('projectId');
      this.ownershipId = this.getQuery('ownershipId');
      this.ownershipGroupId = this.getQuery('ownershipGroupId');
      this.orderType = this.getQuery('orderType');
    }

    var token = this.getQuery('token');

    if (token) {
      this.authorization = token;
      setToken(token);
    } //console.log(this.clientId + "," + this.projectId + "," + this.ownershipId + "," + this.ownershipGroupId)


    this.ownershipCheck();
  },
  methods: {
    goRegister: function goRegister() {
      this.$router.replace({
        path: '/transfer/register?clientId=' + this.clientId + '&projectId=' + this.projectId + '&ownershipId=' + this.ownershipId + '&ownershipGroupId=' + this.ownershipGroupId + '&orderType=' + this.orderType
      });
    },
    ownershipCheck: function ownershipCheck() {
      var _this = this;

      request({
        url: 'user/ownership/check',
        method: 'post',
        data: {
          clientId: this.clientId,
          projectId: this.projectId,
          ownershipId: this.ownershipId,
          ownershipGroupId: this.ownershipGroupId
        }
      }).then(function (res) {
        console.log(res);

        if (res.data.data === true) {
          //TODO
          //this.$message.success('有权益信息')
          if (_this.orderType == 'VIDEO_DOCTOR') {} else if (_this.orderType == 'ONLINE_NURSING_APPOINTMENT') {
            _this.$router.replace({
              path: '/nursing/rights-list?authorization=' + _this.authorization
            });
          } else if (_this.orderType == 'ONLINE_REHABILITATION_CONSULTANT') {
            _this.$router.replace({
              path: '/kfsp/index?clientId=' + _this.clientId + '&projectId=' + _this.projectId + '&ownershipId=' + _this.ownershipId + '&orderType=' + _this.orderType + '&authorization=' + _this.authorization
            });
          } else if (_this.orderType == 'GENE_TESTING') {
            _this.$router.replace({
              path: '/gene-test/index?clientId=' + _this.clientId + '&projectId=' + _this.projectId + '&ownershipGroupId=' + _this.ownershipGroupId + '&orderType=' + _this.orderType + '&authorization=' + _this.authorization
            });
          } else if (_this.orderType == 'DENTAL_CHECKING') {
            _this.$router.replace({
              path: '/dental/detail?clientId=' + _this.clientId + '&projectId=' + _this.projectId + '&ownershipId=' + _this.ownershipId + '&ownershipGroupId=' + _this.ownershipGroupId + '&orderType=' + _this.orderType + '&authorization=' + _this.authorization
            });
          } else if (_this.orderType == 'HOME_SELF_CHECKING') {
            _this.$router.replace({
              path: '/home-self-checking/detail?clientId=' + _this.clientId + '&projectId=' + _this.projectId + '&ownershipId=' + _this.ownershipId + '&ownershipGroupId=' + _this.ownershipGroupId + '&orderType=' + _this.orderType + '&authorization=' + _this.authorization
            });
          }
        } else {
          _this.msg = '未检测到您有此权益';

          _this.$message.error(_this.msg); //this.goRegister();

        }
      });
    }
  }
};