import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { setToken } from '@/utils/auth';
import request from '@/utils/request';
import "./index.scss";
import wx from 'weixin-js-sdk';
import { saveAs } from 'file-saver';
import moment from 'moment';
export default {
  name: 'Index',
  data: function data() {
    return {
      tab: 1,
      dialogVisible: false,
      appointDesc: ['1、请提前24小时预约；', '2、视频服务在预约时间开启后，请尽快呼入，如没有在预约时间内呼入，则该服务被视为放弃使用，如需使用，请再次预约；', '3、咨询结束后，请在48小时后查看咨询报告；', '4、如咨询过程中视频意外中断，您可以在预约的时段内重新发起呼叫，逾期，则需要重新预约。'],
      appointmentList: [],
      consultList: [],
      doneItem: null,
      miniprogramEvn: false,
      csTel: '4006182298',
      modal: false,
      hint: '',
      pdfUrl: ''
    };
  },
  computed: {},
  created: function created() {
    this.initCommonParam();
    var authorization = this.getQuery('authorization');

    if (authorization) {
      setToken(authorization);
    }

    var __this = this;

    wx.miniProgram.getEnv(function (res) {
      __this.miniprogramEvn = res.miniprogram;
    });
    this.initQuery();
    this.wxConfig();
    this.getAppointmentList();
    this.getConsultList();
  },
  mounted: function mounted() {
    window.addEventListener("popstate", this.returnMiniApp, false);
  },
  destroyed: function destroyed() {
    window.removeEventListener("popstate", this.returnMiniApp, false);
  },
  methods: {
    returnMiniApp: function returnMiniApp() {
      // 跳转到上一个小程序页面
      wx.miniProgram.navigateBack({
        delta: 1
      });
    },
    onClickAppointmentStatus: function onClickAppointmentStatus(item) {
      if (item.status == 2 && item.__overdue) {
        this.$message.warning("预约结束时间已过，不能进入咨询室");
      }
    },
    getDoctorSuggestion: function getDoctorSuggestion(item) {
      if (isNull(item.reportUrl)) {
        return '请耐心等待康复报告';
      }

      return '打开康复报告';
    },
    goMiniprogram: function goMiniprogram(roomID, orderId, userId, userName) {
      if (!this.miniprogramEvn) {
        return;
      }

      wx.miniProgram.postMessage({
        data: {
          roomID: roomID,
          orderId: orderId,
          userId: userId,
          userName: userName,
          authorization: this.getQuery('authorization'),
          __debug: this.isDebug()
        }
      });
      wx.miniProgram.navigateBack();
    },
    getIndexPath: function getIndexPath(roomID, orderId) {
      //let indexPath = "pages/home/index.html?authorization=" + this.getQuery('authorization') + '&roomID=' + roomID + '&__debug=' + this.isDebug()
      //console.log(indexPath)
      var indexPath = "pages/video/mainPage/videoMain.html?reportNo=" + orderId + "&roomId=" + roomID;
      return indexPath;
    },
    goServiceUserDetail: function goServiceUserDetail(id) {
      this.$router.push({
        path: '/kfsp/doctor-detail?id=' + id + '&clientId=' + this.clientId + '&projectId=' + this.projectId + '&ownershipId=' + this.ownershipId
      });
    },
    goReportDetail: function goReportDetail(id) {
      this.$router.push({
        path: '/kfsp/report-detail?id=' + id + '&clientId=' + this.clientId + '&projectId=' + this.projectId + '&ownershipId=' + this.ownershipId
      });
    },
    goComment: function goComment() {
      this.$router.push({
        path: '/kfsp/comment?orderId=' + this.doneItem.orderId
      });
    },
    goAppointment: function goAppointment() {
      this.$router.push({
        path: '/kfsp/appoint?clientId=' + this.clientId + '&projectId=' + this.projectId + '&ownershipId=' + this.ownershipId
      });
    },
    getAppointmentStatus: function getAppointmentStatus(status) {
      if (status == 0) {
        //已失效
        return '/static/images/kfsp/index/bt_cancel.png';
      }

      if (status == 1) {
        //待开始
        return '/static/images/kfsp/index/bt_no_start.png';
      }

      if (status == 2) {
        //进行中
        return '/static/images/kfsp/index/bt_consult.png';
      }

      if (status == 3) {
        //已完成
        return '/static/images/kfsp/index/bt_done.png';
      }

      if (status == 4) {
        //已取消/已超时
        return '/static/images/kfsp/index/bt_time_out.png';
      }
    },
    getCsTel: function getCsTel() {
      var _this = this;

      request({
        url: '/appointment/cs-tel',
        method: 'post',
        data: {
          clientId: this.clientId,
          projectId: this.projectId,
          ownershipId: this.ownershipId,
          type: "ONLINE_REHABILITATION_CONSULTANT"
        }
      }).then(function (res) {
        console.log(res);
        _this.csTel = res.data.tel;
      });
    },
    getConsultList: function getConsultList() {
      var _this2 = this;

      request({
        url: 'consult/list',
        method: 'post',
        data: {
          clientId: this.clientId,
          projectId: this.projectId,
          ownershipId: this.ownershipId,
          type: "ONLINE_REHABILITATION_CONSULTANT"
        }
      }).then(function (res) {
        console.log(res);
        _this2.consultList = res.data.data;
      });
    },
    getAppointmentList: function getAppointmentList() {
      var _this3 = this;

      request({
        url: 'appointment/list',
        method: 'post',
        data: {
          clientId: this.clientId,
          projectId: this.projectId,
          ownershipId: this.ownershipId,
          type: "ONLINE_REHABILITATION_CONSULTANT"
        }
      }).then(function (res) {
        console.log(res);

        if (res.status === 200) {
          _this3.appointmentList = res.data.data;
          var done_item = null;

          _this3.appointmentList.forEach(function (item) {
            var dates = item.date.split('-');
            item.__date = dates[0] + "年" + dates[1] + "月" + dates[2] + "日";

            if (item.status == 2) {
              //进行中
              var __end_date = item.date + ' ' + item.end + ':00';

              var end_date = string2date(__end_date);

              if (new Date().getTime() > end_date.getTime()) {
                item.__overdue = true;
              }
            }

            if (item.status == 1) {
              //待开始
              var __start_date = item.date + ' ' + item.start + ':00';

              var start_date = string2date(__start_date);

              if (new Date().add(2).getTime() < start_date.getTime()) {
                item.__cancel_appoint = true;
              }
            }

            if (item.status == 3 && !item.evaluated) {
              done_item = item;
            }
          });

          _this3.doneItem = done_item;
        }
      });
    },
    cancelAppointment: function cancelAppointment(item) {
      var _this4 = this;

      var __start_date = item.date + ' ' + item.start + ':00';

      var start_date = string2date(__start_date);

      if (new Date().add(2).getTime() > start_date.getTime()) {
        this.$message.warning("咨询开始前2个小时内不可取消");
        return;
      }

      this.$confirm('是否要取消预约[' + item.date + ' ' + item.start + '~' + item.end + ']?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(function () {
        _this4.__cancelAppointment(item.orderId);
      }).catch(function () {});
    },
    __cancelAppointment: function __cancelAppointment(id) {
      var _this5 = this;

      request({
        url: 'appointment/' + id + '/cancel',
        method: 'delete'
      }).then(function (res) {
        console.log(res);

        _this5.$confirm('预约取消成功', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success'
        }).then(function () {
          _this5.getAppointmentList();
        }).catch(function () {});
      });
    },
    clickTab: function clickTab(tab) {
      this.tab = tab;

      if (tab == 1) {
        this.getAppointmentList();
      } else if (tab == 2) {
        this.getConsultList();
      }
    },
    download: function download(url) {
      var userAgent = navigator.userAgent; // isMini是否在小程序环境中

      var isMini = /miniProgram/i.test(userAgent); // isiOS是否是ios终端

      var isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (isMini) {
        this.modal = true;
        this.pdfUrl = url;

        if (isiOS) {
          this.hint = '即将跳转到报告预览页面，您可以通过预览页面右上角的分享功能，将报告分享到微信中';
        } else {
          this.hint = '即将跳转到报告预览页面，您可以通过预览页面右上角的分享功能，将报告分享到微信中；或者直接保存到手机本地';
        }
      } else {
        var fileName = "康复报告" + moment().format("YYYYMMDD") + ".pdf";
        console.log(fileName);
        saveAs(url, fileName);
      }
    },
    goMini: function goMini() {
      this.modal = false;
      var userAgent = navigator.userAgent;
      var isMini = /miniProgram/i.test(userAgent);
      var isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1;
      var pageUrl = "/pages/download/index?url=".concat(this.pdfUrl);

      if (isMini) {
        if (isAndroid) {
          pageUrl = pageUrl + '.pdf';
        }

        wx.miniProgram.navigateTo({
          url: pageUrl
        });
      }
    }
  }
};