var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selfCompleteInfo" },
    [
      _c("div", { staticClass: "rightsInfo" }, [
        _c("div", { staticClass: "serviceContent" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "contentInfo", on: { click: _vm.goDetail } },
            [
              _c("div", { staticClass: "itemInfoLeft" }, [
                _c("img", { attrs: { src: _vm.iconUrl, alt: "" } }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "itemInfoRight" }, [
                _c("div", { staticClass: "rightsTitle" }, [
                  _vm._v("\n            " + _vm._s(_vm.title) + "\n          "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "itemInfoRightBottom" }, [
                  _c("div", { staticClass: "rightsPrice" }, [
                    _vm._v("¥" + _vm._s(_vm.originalPrice)),
                  ]),
                  _vm._v(" "),
                  _vm._m(1),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "serviceInfo" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "infoForm" }, [
            _c("div", { staticClass: "name formItem" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v("\n            姓名\n          "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.userName) + "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "phone formItem" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v("\n            联系电话\n          "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v("\n            " + _vm._s(_vm.phone) + "\n          "),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bodyStatus formItem" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v("\n            性别\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "value",
                  on: {
                    click: function ($event) {
                      return _vm.openSwitch("bodyStatusVisible")
                    },
                  },
                },
                [
                  !_vm.gender && _vm.gender !== 0
                    ? _c("div", { staticClass: "placeholder" }, [
                        _vm._v("\n              请选择性别\n            "),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.bodyStatusName) +
                            "\n            "
                        ),
                      ]),
                  _vm._v(" "),
                  _vm._m(3),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "age formItem" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v("\n            年龄\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "value" },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 1,
                      placeholder: "请输入年龄",
                      maxlength: "200",
                    },
                    model: {
                      value: _vm.age,
                      callback: function ($$v) {
                        _vm.age = $$v
                      },
                      expression: "age",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "area formItem" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v("\n            所在地区\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "value",
                  on: {
                    click: function ($event) {
                      _vm.showAddress = true
                    },
                  },
                },
                [
                  !_vm.area
                    ? _c("div", { staticClass: "placeholder" }, [
                        _vm._v(
                          "\n              省、市、区、街道\n            "
                        ),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.area) +
                            "\n            "
                        ),
                      ]),
                  _vm._v(" "),
                  _vm._m(4),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "address formItem" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v("\n            详细地址\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "value" },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      placeholder: "请输入详细地址",
                      maxlength: "200",
                    },
                    model: {
                      value: _vm.address,
                      callback: function ($$v) {
                        _vm.address = $$v
                      },
                      expression: "address",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "comment formItem" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v("\n            备注\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "value" },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      placeholder: "请输入备注信息",
                      maxlength: "200",
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "submitOrder", on: { click: _vm.submitOrder } },
          [_vm._v("\n      提交订单\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c("nut-picker", {
        attrs: {
          "is-visible": _vm.bodyStatusVisible,
          "list-data": _vm.bodyStatusList,
        },
        on: {
          close: function ($event) {
            return _vm.closeSwitch("bodyStatusVisible")
          },
          confirm: _vm.confirm,
        },
      }),
      _vm._v(" "),
      _c("nut-address", {
        attrs: {
          province: _vm.province,
          city: _vm.city,
          country: _vm.country,
          town: _vm.town,
          "custom-address-title": "请选择所在地区",
        },
        on: { onChange: _vm.onChange1, close: _vm.close1 },
        model: {
          value: _vm.showAddress,
          callback: function ($$v) {
            _vm.showAddress = $$v
          },
          expression: "showAddress",
        },
      }),
      _vm._v(" "),
      _c(
        "nut-actionsheet",
        {
          attrs: {
            "is-visible": _vm.isVisible,
            "menu-items": _vm.menuItems,
            "choose-tag-value": "确定",
            "cancel-txt": "取消",
          },
          on: { close: _vm.switchActionSheet, choose: _vm.makeSureSubmit },
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _c("label", [_vm._v("提交后不可修改， 是否确认提交？")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blockTop" }, [
      _c("div", { staticClass: "titleLeft" }),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n          权益信息\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rightsNum" }, [
      _vm._v("X "),
      _c("span", [_vm._v("1")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blockTop" }, [
      _c("div", { staticClass: "titleLeft" }),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n          服务信息\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tip" }, [
        _vm._v("\n          请输入以下所有信息\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chooseIcon" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chooseIcon" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }