import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      dialogVisible: false,
      dialogImg: null,
      reportDetail: {}
    };
  },
  created: function created() {
    this.initQuery();
    var id = this.getQuery('id');
    this.getDetail(id);
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  beforeCreate: function beforeCreate() {},
  beforeDestroy: function beforeDestroy() {},
  methods: {
    goServiceUserDetail: function goServiceUserDetail() {
      var id = this.reportDetail.serviceUserId;
      this.$router.push({
        path: '/kfsp/doctor-detail?id=' + id
      });
    },
    checkNull: function checkNull(reportDetail) {
      if (isNull(reportDetail)) {
        return true;
      }

      var length = arguments.length;

      for (var i = 1; i < length; i++) {
        reportDetail = reportDetail[arguments[i]];

        if (isNull(reportDetail)) {
          return true;
        }
      }

      return false;
    },
    onClickImg: function onClickImg(item) {
      this.dialogVisible = true;
      this.dialogImg = item;
    },
    getDetail: function getDetail(id) {
      var _this = this;

      request({
        url: 'consult/' + id,
        method: 'get'
      }).then(function (res) {
        console.log(res);
        _this.reportDetail = res.data.data;
        var start = _this.reportDetail.date + ' ' + _this.reportDetail.start + ':00';
        var end = _this.reportDetail.date + ' ' + _this.reportDetail.end + ':00';
        var start_date = string2date(start);
        var end_date = string2date(end);
        _this.reportDetail.__diff = start_date.diff(end_date);
        var __case = _this.reportDetail.consultResult['基本情况']['病历资料'];

        if (isNull(__case)) {
          _this.reportDetail.consultResult['基本情况']['病历资料'] = [];
        } else {
          _this.reportDetail.consultResult['基本情况']['病历资料'] = __case.split(',');
          var __imgs = [];

          _this.reportDetail.consultResult['基本情况']['病历资料'].forEach(function (item) {
            __imgs.push(getImage(item));
          });

          _this.reportDetail.consultResult['基本情况']['病历资料'] = __imgs;
        }
      });
    }
  }
};