var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "step-detail" }, [
    _c("div", { staticClass: "detail-content" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n        权益信息\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item", on: { click: _vm.goDetail } }, [
        _c("div", { staticClass: "left" }, [
          _c("img", { attrs: { src: _vm.getImage(_vm.detail.iconUrl) } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n            " + _vm._s(_vm.detail.name) + "\n          "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "price" }, [
            _c("div", { staticClass: "total-font" }, [
              _c("div", { staticClass: "total" }, [
                _vm._v(
                  "\n                ¥" +
                    _vm._s(_vm.detail.originalPrice) +
                    "\n              "
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "detail-content" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n        服务信息\n      "),
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n            客户姓名\n          "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.detail.userName) + "\n          "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n            手机号\n          "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.detail.mobile) + "\n          "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("div", { staticClass: "name" }, [
              _vm._v("\n            证件类型\n          "),
            ]),
            _vm._v(" "),
            _vm._l(this.idTypeList, function (value, idx) {
              return _c("div", [
                value.label === Number(_vm.detail.idType)
                  ? _c("div", { staticClass: "value" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(value.value) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n            身份证号\n          "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.detail.idCard) + "\n          "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n            性别\n          "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.detail.gender === "1" ? "男" : "女") +
                "\n          "
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.detail.orderType != "PSYCHOLOGICAL_COUNSELING"
          ? _c("li", [
              _c("div", { staticClass: "name" }, [
                _vm._v("\n            身体状态\n          "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.detail.bodyStatus === "1"
                        ? "可行动"
                        : _vm.detail.bodyStatus === "2"
                        ? "半失能"
                        : "半失能"
                    ) +
                    "\n          "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.detail.orderType != "PSYCHOLOGICAL_COUNSELING"
          ? _c("li", [
              _c("div", { staticClass: "name" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.detail.orderType === "LEAVE_HOSPITAL_TRAFFIC_ARRANGE"
                        ? "出院时间"
                        : "服务时间"
                    ) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.detail.serviceStartTime) +
                    "\n          "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.detail.orderType != "PSYCHOLOGICAL_COUNSELING"
          ? _c("li", [
              _c("div", { staticClass: "name" }, [
                _vm._v("\n            地址\n          "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.detail.address) + "\n          "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.detail.orderType != "LEAVE_HOSPITAL_TRAFFIC_ARRANGE" &&
        _vm.detail.orderType != "SEE_A_DOCTOR" &&
        _vm.detail.orderType != "PSYCHOLOGICAL_COUNSELING"
          ? _c("li", [
              _c("div", { staticClass: "name" }, [
                _vm._v("\n            证明材料\n          "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "img" },
                _vm._l(_vm.detail.materialImages, function (url, idx) {
                  return _c("div", { key: idx, staticClass: "images" }, [
                    _c("img", { attrs: { src: _vm.getImage(url) } }),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n            备注\n          "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.detail.comment) + "\n          "
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "detail-content mt-56" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n        订单信息\n      "),
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n            订单编号\n          "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.detail.orderId) + "\n          "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("div", { staticClass: "name" }, [
            _vm._v("\n            下单时间\n          "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.detail.createTime) + "\n          "
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.step === 4 && !_vm.detail.evaluated
      ? _c(
          "div",
          {
            staticClass: "evaluation-submit",
            on: { click: _vm.handleEvaluationCreate },
          },
          [_vm._v("\n      立即评价\n    ")]
        )
      : _vm.step === 4 && _vm.detail.evaluated
      ? _c(
          "div",
          {
            staticClass: "evaluation-submit",
            on: { click: _vm.handleEvaluationDetail },
          },
          [_vm._v("\n      查看评价\n    ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "num" }, [
      _c("span", [_vm._v("x")]),
      _vm._v("1\n            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }