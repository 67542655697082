var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c("img", {
        staticClass: "img",
        attrs: { src: "/static/images/cz/detail/ic1.png" },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { position: "relative" } }, [
        _c("img", {
          staticClass: "img",
          attrs: { src: "/static/images/cz/detail/ic2.png" },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "img see-detail",
          attrs: { src: "/static/images/cz/detail/ic_bt1.png" },
          on: {
            click: function ($event) {
              _vm.dialogVisible = true
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "img",
        attrs: { src: "/static/images/cz/detail/ic3.png" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "img",
        attrs: { src: "/static/images/cz/detail/ic4.png" },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "2rem" } }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticClass: "img",
            attrs: { src: "/static/images/cz/detail/ic_detail1.png" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "img",
            attrs: { src: "/static/images/cz/detail/ic_detail2.png" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "img",
            attrs: { src: "/static/images/cz/detail/ic_detail3.png" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "img",
            attrs: { src: "/static/images/cz/detail/ic_detail4.png" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "img",
            attrs: { src: "/static/images/cz/detail/ic_detail5.png" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "img",
            attrs: { src: "/static/images/cz/detail/ic_detail6.png" },
          }),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "1.2rem" } }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }