import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      consultDetail: {},
      dialogVisible: false,
      dialogImg: null
    };
  },
  created: function created() {
    this.initQuery();
    var id = this.getQuery('id');
    this.getConsultDetail(id);
  },
  computed: {},
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    getAvatar: function getAvatar(avatar) {
      if (isNull(avatar)) {
        return '/static/images/ic_default_avatar.png';
      }

      return getImage(avatar);
    },
    getConsultDetail: function getConsultDetail(id) {
      var _this = this;

      request({
        url: 'consult/service-user/' + id,
        method: 'get'
      }).then(function (res) {
        console.log(res);
        _this.consultDetail = res.data.data;
        var certificateImages = _this.consultDetail.certificateImages;
        var __certificateImages = [];

        if (certificateImages) {
          certificateImages.forEach(function (item) {
            __certificateImages.push(getImage(item));
          });
        }

        _this.consultDetail.certificateImages = __certificateImages;
      });
    },
    onClickImg: function onClickImg(item) {
      this.dialogVisible = true;
      this.dialogImg = item;
    }
  }
};