var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("img", {
      staticClass: "header-bg",
      attrs: { src: "/static/images/diagnose-detail/header.png" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "row flex" }, [
      _c("div", { staticClass: "left" }, [_vm._v("患者信息")]),
      _vm._v(" "),
      _c("div", { staticClass: "right" }, [
        _c("span", { staticStyle: { display: "block" } }, [
          _vm._v(_vm._s(_vm.detail.name)),
        ]),
        _vm._v(" "),
        _c(
          "span",
          { staticStyle: { display: "block", "margin-top": "-0.4rem" } },
          [_vm._v(_vm._s(_vm.detail.sex))]
        ),
        _vm._v(" "),
        _c(
          "span",
          { staticStyle: { display: "block", "margin-top": "-0.4rem" } },
          [_vm._v(_vm._s(_vm.detail.age))]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.detail.mainSuit
      ? _c("div", { staticClass: "row flex" }, [
          _c("div", { staticClass: "left" }, [_vm._v("患者主诉")]),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.detail.mainSuit)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.detail.summary
      ? _c("div", { staticClass: "row flex" }, [
          _c("div", { staticClass: "left" }, [_vm._v("医生意见")]),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.detail.summary)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.detail.diseaseDescn
      ? _c("div", { staticClass: "row flex" }, [
          _c("div", { staticClass: "left" }, [_vm._v("现病史")]),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.detail.diseaseDescn)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.detail.pasHis
      ? _c("div", { staticClass: "row flex" }, [
          _c("div", { staticClass: "left" }, [_vm._v("既往史")]),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.detail.pasHis)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.detail.famHis
      ? _c("div", { staticClass: "row flex" }, [
          _c("div", { staticClass: "left" }, [_vm._v("家族史")]),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.detail.famHis)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.detail.allergy
      ? _c("div", { staticClass: "row flex" }, [
          _c("div", { staticClass: "left" }, [_vm._v("过敏史")]),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.detail.allergy)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }