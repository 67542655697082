//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import request from '@/utils/request'
import "./index.scss"; // 崇左项目

export default {
  name: 'index',
  data: function data() {
    return {
      dialogVisible: false,
      desc1: "您提交的材料将于24小时内完成审核",
      desc2: "您提交的材料【%s】，请检查后重新上传，如有疑问，请致电xxxxxx咨询。"
    };
  },
  created: function created() {},
  computed: {},
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    activeConfirm: function activeConfirm() {
      this.$confirm('确定现在激活视频医生服务吗？激活后24小时内有效。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {}).catch(function () {});
    }
  }
};