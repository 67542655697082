//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import "./index.scss";
export default {
  name: 'Detail',
  methods: {
    goInformation: function goInformation() {
      this.$router.push({
        path: "/gene-test/information",
        query: {
          clientId: this.$route.query.clientId,
          projectId: this.$route.query.projectId,
          ownershipId: this.$route.query.ownershipId,
          ownershipGroupId: this.$route.query.ownershipGroupId,
          id: this.$route.query.id
        }
      });
    }
  }
};