var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "serviceDetail" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "serviceInfo" }, [
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "appointment", on: { click: _vm.gotoActivateCode } },
        [_vm._v("\n      获取激活码\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: "https://test-dashu-pimh-mini-apps-resource-1303893949.cos.ap-shanghai.myqcloud.com/assets/icon/dental.jpeg",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contentInfo" }, [
      _vm._v(
        "\n      全国200家品牌诊所通用（包括科瓦、维乐口腔等各大品牌连锁）"
      ),
      _c("br"),
      _vm._v("\n      节假日通用，全程透明，无隐形消费"),
      _c("br"),
      _vm._v("\n      如发现任何问题可马上联络在线客服协助处理"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "serviceContent" }, [
      _c("div", { staticClass: "blockTop" }, [
        _c("div", { staticClass: "titleLeft" }),
        _vm._v(" "),
        _c("div", { staticClass: "title" }, [
          _vm._v("\n          服务内容/流程\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "contentInfo" }, [
        _c("div", [_vm._v("\n          - 初诊挂号\n        ")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n          建立个性化口腔终生纸质/电子档案，量身定制口腔保健、回访计划。"
          ),
          _c("br"),
          _vm._v(
            "\n          使用一次性口腔器械盒，全程享受专人专机专用设备服务\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("\n          - 十项口腔检查\n        ")]),
        _vm._v(" "),
        _c("div", [_vm._v("\n          - 进口超声波洁牙\n        ")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n          采用进口超声波洁牙技术，通过超声波震动，使顽固牙结石大面积迅速脱离牙齿，全程轻松、舒适，洗完牙齿犹如新生，口气清新，堪称口腔诊疗SPA。\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("\n          - 全口脱敏抛光\n        ")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n          对牙面进行细致抛光，感受抛光时产生的细密小泡沫在嘴里肆意跳跃的微痒感，这是洁牙后的一次彻底放松，不仅可使牙面长时间保持光洁，更能有效缓解牙齿敏感带来的不适感。\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("\n          - 口腔宣教\n        ")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n          医护人员为您一对一进行口腔保健宣教，对个人口腔情况进行详细说明解释以及答疑，任何口腔疑问都将得到满意解答。\n        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "serviceFlow" }, [
      _c("div", { staticClass: "blockTop" }, [
        _c("div", { staticClass: "titleLeft" }),
        _vm._v(" "),
        _c("div", { staticClass: "title" }, [
          _vm._v("\n          【洗牙须知】：\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "contentInfo" }, [
        _c("div", [
          _vm._v(
            "\n          1、在洁牙过程中会感到牙齿有点酸，请放心，这不会影响牙齿健康；\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("2、心脏病患者、乙肝患者、孕妇、刚拔完牙的顾客不适合做洁牙；"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("3、15岁年龄以下处于发育期的青少年，做洁牙容易造成不适感；"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("4、洗牙后应避免吸烟、饮用茶、咖啡、可乐、红酒等有色食品；"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n          5、牙石是刷不掉的，只能通过洗牙来去除，一般建议1年洗牙2-3次。\n        "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip" }, [
      _c("div", { staticClass: "contentInfo" }, [
        _c("div", [_vm._v("\n          建议提前3天进行在线预约。\n        ")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n          建议在医生检查后定制个性化的治疗项目。\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n          具体可预约诊所及预约时间以“宇宙熊医生”小程序内实际可预约为准。\n        "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }