var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex audit-result",
      staticStyle: {
        "flex-direction": "column",
        height: "100%",
        "justify-content": "flex-start",
      },
    },
    [
      _c("img", { staticClass: "img", attrs: { src: _vm.img } }),
      _vm._v(" "),
      _vm.auditPending
        ? _c("div", { staticClass: "status-pending" }, [_vm._v("审核中")])
        : _vm.auditFail
        ? _c("div", { staticClass: "status-fail" }, [_vm._v("审核不通过")])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.desc))]),
      _vm._v(" "),
      _vm.auditFail
        ? _c("div", { staticClass: "bt flex", on: { click: _vm.goRegister } }, [
            _vm._v("重新上传"),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }