var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gene-information", on: { touchmove: _vm.onTouchMove } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("检测项目")]),
            _vm._v(" "),
            this.$route.query.id === 1
              ? _c("div", { staticClass: "value" }, [
                  _vm._v("儿童用药抗感染类(8项）"),
                ])
              : this.$route.query.id === 2
              ? _c("div", { staticClass: "value" }, [
                  _vm._v("儿童天赋（22项）"),
                ])
              : _c("div", { staticClass: "value" }, [
                  _vm._v("儿童营养吸收（12项）"),
                ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("受检者姓名")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "value" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请填写受检者姓名" },
                  model: {
                    value: _vm.name,
                    callback: function ($$v) {
                      _vm.name = $$v
                    },
                    expression: "name",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("受检者性别")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "value",
                on: {
                  click: function ($event) {
                    _vm.setVisible = true
                  },
                },
              },
              [
                _c("div", { staticClass: "val" }, [_vm._v(_vm._s(_vm.sex))]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: "/static/images/gene-test/down.png" },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("受检者年龄")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "value" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请填写受检者年龄" },
                  model: {
                    value: _vm.age,
                    callback: function ($$v) {
                      _vm.age = $$v
                    },
                    expression: "age",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("联系电话")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "value" },
              [
                _c("el-input", {
                  attrs: { maxlength: "11", placeholder: "请填写联系电话" },
                  model: {
                    value: _vm.phone,
                    callback: function ($$v) {
                      _vm.phone = $$v
                    },
                    expression: "phone",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "label" }, [_vm._v("选择地区")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "value",
                on: {
                  click: function ($event) {
                    _vm.showAddress = true
                  },
                },
              },
              [
                _vm.area
                  ? _c("div", { staticClass: "val" }, [
                      _vm._v(_vm._s(_vm.area)),
                    ])
                  : _c("div", { staticClass: "placeholder" }, [
                      _vm._v("省、市、区、街道"),
                    ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: "/static/images/gene-test/down.png" },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item address" }, [
            _c("div", { staticClass: "label" }, [_vm._v("详细地址")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "value" },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    autosize: "",
                    placeholder: "请输入详细地址",
                    maxlength: "200",
                  },
                  model: {
                    value: _vm.address,
                    callback: function ($$v) {
                      _vm.address = $$v
                    },
                    expression: "address",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "submit", on: { click: _vm.check } }, [
          _vm._v("提交"),
        ]),
      ]),
      _vm._v(" "),
      _vm.modal
        ? _c("div", { staticClass: "modal" }, [
            _c("div", { staticClass: "mask" }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "wrap",
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v("提交后不可修改，是否提交？"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "confim" }, [
                  _c(
                    "div",
                    {
                      staticClass: "cancel",
                      on: {
                        click: function ($event) {
                          _vm.modal = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ok", on: { click: _vm.submit } }, [
                    _vm._v("确定"),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("nut-picker", {
        attrs: { "is-visible": _vm.setVisible, "list-data": _vm.setList },
        on: {
          close: function ($event) {
            _vm.setVisible = false
          },
          confirm: _vm.confirm,
        },
      }),
      _vm._v(" "),
      _c("nut-address", {
        attrs: {
          province: _vm.province,
          city: _vm.city,
          country: _vm.country,
          town: _vm.town,
          "custom-address-title": "请选择所在地区",
        },
        on: { onChange: _vm.onChange, close: _vm.close },
        model: {
          value: _vm.showAddress,
          callback: function ($$v) {
            _vm.showAddress = $$v
          },
          expression: "showAddress",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prompt" }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: "/static/images/gene-test/hint.png" },
      }),
      _vm._v("\n    页面信息为采样棒绑定和寄送相关信息，请正确填写\n  "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }