//
//
//
//
//
//
//
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {};
  },
  created: function created() {
    console.log("===========no right===========");
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {}
};