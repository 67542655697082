var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "min-height": "100vh", background: "#fff" } },
      [_c("div", { attrs: { id: "preview-pdf-content" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }