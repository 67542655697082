//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from "moment";
import "./index.scss";
export default {
  name: 'StepFlow',
  props: {
    detail: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      step: 1,
      time: null
    };
  },
  watch: {
    detail: function detail() {
      if (this.detail.serviceState === "3") {
        //取消
        this.step = 5;
      } else if (this.detail.serviceState === "1") {
        this.step = 3;
      } else if (this.detail.serviceState === "2") {
        this.step = 4;
      }

      if (this.detail.serviceStartTime) {
        this.time = moment(this.detail.serviceStartTime).format("YYYY年MM月DD日 HH:mm");
      }
    }
  }
};