var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticStyle: { height: "2.14rem" } }),
      _vm._v(" "),
      _c("img", {
        staticClass: "empty-data-img",
        attrs: { src: "/static/images/no-right/empty-data.png" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "empty-data flex" }, [
        _vm._v("抱歉，未检测到您的权益"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }