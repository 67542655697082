var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "serviceDetail" }, [
    _vm.step === 1
      ? _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/fuli/1.png"), alt: "" },
          }),
        ])
      : _vm.step === 2
      ? _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/fuli/2.png"), alt: "" },
          }),
        ])
      : _vm.step === 3
      ? _c("div", [
          _c("img", {
            attrs: { src: require("@/assets/fuli/3.png"), alt: "" },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "appointment" }, [
      _vm.buttonShow
        ? _c(
            "div",
            { staticClass: "button-appointment", on: { click: _vm.goDetail } },
            [_vm._v("\n      立即预约\n    ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }