var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "doctor-detail" },
    [
      _c("div", { staticClass: "top" }, [
        _c("img", {
          staticClass: "img",
          attrs: { src: _vm.getAvatar(_vm.consultDetail.avatar) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.consultDetail.serviceUserName)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { padding: "0 0.48rem" } }, [
        _c("div", { staticClass: "label" }, [_vm._v("擅长领域")]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [
          _vm._v(_vm._s(_vm.consultDetail.introduction)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label" }, [_vm._v("相关证书")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex",
          staticStyle: {
            "justify-content": "flex-start",
            "flex-wrap": "wrap",
            "margin-left": "0.48rem",
          },
        },
        _vm._l(_vm.consultDetail.certificateImages, function (item, key) {
          return _c("img", {
            key: key,
            staticClass: "certificate-img",
            attrs: { src: item },
            on: {
              click: function ($event) {
                return _vm.onClickImg(item)
              },
            },
          })
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "1.6rem" } }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%", height: "auto" },
            attrs: { src: _vm.dialogImg },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }