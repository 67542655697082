var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gene-detail" }, [
    _c("div", { staticClass: "header" }, [
      this.$route.query.id === 1
        ? _c("div", { staticClass: "name" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("span", { staticClass: "num" }, [_vm._v("8项")]),
          ])
        : this.$route.query.id === 2
        ? _c("div", { staticClass: "name" }, [
            _c("span", [_vm._v("儿童天赋")]),
            _vm._v(" "),
            _c("span", { staticClass: "num" }, [_vm._v("男/女 22项")]),
          ])
        : _c("div", { staticClass: "name" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("span", { staticClass: "num" }, [_vm._v("男/女 12项")]),
          ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "img",
        attrs: { src: "/static/images/gene-test/yongyao2.png" },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("检测项目")]),
        _vm._v(" "),
        this.$route.query.id === 1
          ? _c("div", { staticClass: "desc" }, [
              _vm._v(
                "氟氯西林毒副作用、阿莫西林毒副作用、阿奇霉素疗效、红霉素代谢、醋酸泼尼松毒副作用、伏立康唑代谢、氨基糖苷类药物、利巴韦林和聚乙二醇化干扰素毒副作用。"
              ),
            ])
          : this.$route.query.id === 2
          ? _c("div", { staticClass: "desc" }, [
              _vm._v(
                "社交焦虑障碍、求刺激倾向、新奇探索、毅力、责任心、绝对音高、专注能力、创造能力、语言表达能力、音乐天赋、学习能力、记忆力、阅读能力、自律能力、数学天赋、错误学习、面部情绪感知、领导能力、正强化反应、幸福感。"
              ),
            ])
          : _c("div", { staticClass: "desc" }, [
              _vm._v(
                "维生素A需求、维生素B2需求、维生素B6需求、叶酸水平降低风险、维生素B12需求、维生素C需求、维生素D需求、维生素E需求、钙元素需求、锌元素需求、硒元素需求、铁元素需求。"
              ),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "title" }, [_vm._v("电子报告周期（工作日）")]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [_vm._v("7个工作日")]),
        _vm._v(" "),
        _c("div", { staticClass: "title" }, [_vm._v("样本类型")]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [_vm._v("拭子/唾液/血液")]),
      ]),
      _vm._v(" "),
      _vm._m(2),
    ]),
    _vm._v(" "),
    this.$route.query.clientId &&
    this.$route.query.projectId &&
    this.$route.query.ownershipGroupId &&
    this.$route.query.ownershipGroupId !== "undefined"
      ? _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "btn", on: { click: _vm.goInformation } }, [
            _vm._v("申请检测"),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("儿童安全用药抗"), _c("br"), _vm._v("感染类")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("儿童营养吸收"), _c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flow" }, [
      _c("div", { staticClass: "title" }, [_vm._v("采样流程")]),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        _vm._v("口腔粘膜细胞采样流程"),
        _c("span", [_vm._v("（仅供参考，详见说明书）")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step" }, [
        _vm._v("采集样本30 min前，清水漱口，漱后禁食；"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step two" }, [
        _vm._v(
          "采样拭子由采样冠、采样套杆和采样推杆三部分组成。使用时根据包装提示，撕开口腔拭子外包装，手拿采样套杆尾端取出拭子（注意：整个取样过程中手不能接触采样冠部分）；"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step three" }, [
        _vm._v(
          "将有采样冠一端伸入口腔内，用采样冠刮拭口腔内壁在口中内腮上下刮拭40次以上，力度以腮部微凸为准。"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step four" }, [
        _vm._v(
          "在刮拭后，可单手持采样拭子，用拇指抵住采样推杆采样后用手推采样拭子后杆，将采样冠顶入（或折断入）采样管内，拧紧采样管盖，便可在常温下进行保存。一个采样冠对应一个采样管。（注意采集管上贴好条形码）"
        ),
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "process1",
        attrs: { src: "/static/images/gene-test/process1.png" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        _vm._v("唾液采集器采样流程"),
        _c("span", [_vm._v("（仅供参考，详见说明书）")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step" }, [
        _vm._v("采集样本30 min前，清水漱口、漱后禁食；"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step two" }, [
        _vm._v(
          "放松并按摩脸颊，将唾液吐进漏斗，直至唾液（不含气泡）量达到唾液填充线高度。"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step three" }, [
        _vm._v(
          "单手持管，确保收集管处于直立状态。用拇指按下防溅盖，直至听到咔哒声，确保盖子盖紧。"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step four" }, [
        _vm._v(
          "另一只手轻轻拉动外露的封膜条带，使DNA稳定液缓缓流入收集管内，与唾液混合。"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step five" }, [
        _vm._v(
          "\t保持收集管处于直立状态，拧下漏斗，并用收集管盖将收集管盖好并拧紧。将拧紧后的收集管上下颠倒混匀10~15次，并丢弃漏斗。（注意收集管上贴好条形码）"
        ),
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "process2",
        attrs: { src: "/static/images/gene-test/process2.png" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }