var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "step-flow" }, [
    _c("div", { staticClass: "step-content" }, [
      _c("div", { staticClass: "content-step" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { class: ["step", { "no-begin": _vm.step < 3 }] }, [
          _vm._m(1),
          _vm._v(" "),
          _c("span", { staticClass: "title" }, [_vm._v("服务中")]),
        ]),
        _vm._v(" "),
        _c("div", { class: ["step", { "no-begin": _vm.step < 4 }] }, [
          _vm._m(2),
          _vm._v(" "),
          _c("span", { staticClass: "title" }, [_vm._v("完成订单")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-info" }, [
        _vm.step === 1 || _vm.step === 4
          ? _c("img", { attrs: { src: "/static/images/nursing/done.png" } })
          : _vm.step === 5
          ? _c("img", { attrs: { src: "/static/images/nursing/user.png" } })
          : _vm.step === 3
          ? _c("img", {
              attrs: { src: "/static/images/nursing/icon_time.png" },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "detail" }, [
          _vm.step === 1
            ? _c("div", { staticClass: "status" }, [_vm._v("下单成功")])
            : _vm.step === 3
            ? _c("div", { staticClass: "status" }, [_vm._v("正在服务中")])
            : _vm.step === 4
            ? _c("div", { staticClass: "status" }, [_vm._v("订单已完成")])
            : _vm._e(),
          _vm._v(" "),
          _vm.step === 1
            ? _c("ul", [
                _c("li", [
                  _vm._v("系统将根据您的订单信息调派护理人员，请耐心等待"),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "客服电话：" + _vm._s(_vm.detail.customerServicePhoneNumber)
                  ),
                ]),
              ])
            : _vm.step === 3
            ? _c("ul", [
                _c("li", [
                  _vm._v("订单服务中，若有疑问请拨打客服电话：4006182298"),
                ]),
              ])
            : _vm.step === 4
            ? _c("ul", [
                _c("li", [
                  _vm._v("订单已完成，若有疑问请拨打客服电话：4006182298。"),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step no-line" }, [
      _c("div", { staticClass: "icon" }, [
        _c("img", { attrs: { src: "/static/images/nursing/step_place.png" } }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("下单")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "/static/images/nursing/step_serve.png" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "/static/images/nursing/step_finish.png" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }