import Vue from 'vue';
import Vuex from 'vuex';
import app from "./modules/app";
import settings from "./modules/settings";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    settings: settings
  }
});
export default store;