var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("div", { staticStyle: { position: "relative" } }, [
        _c("img", {
          staticClass: "top-bg",
          attrs: { src: "/static/images/kfsp/index/top_bg.png" },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "text-align": "center", "z-index": "999" } },
          [
            _vm.doneItem
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "appoint-bt flex",
                      on: { click: _vm.goComment },
                    },
                    [_vm._v("\n          去评价\n        ")]
                  ),
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "appoint-bt flex",
                      on: { click: _vm.goAppointment },
                    },
                    [_vm._v("\n          开始预约\n        ")]
                  ),
                ],
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.modal
        ? _c("div", { staticClass: "modal" }, [
            _c("div", { staticClass: "mask" }),
            _vm._v(" "),
            _c("div", { staticClass: "wrap" }, [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.hint))]),
              _vm._v(" "),
              _c("div", { staticClass: "confim" }, [
                _c("div", { staticClass: "ok", on: { click: _vm.goMini } }, [
                  _vm._v("确定"),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "flex tabs" }, [
        _c(
          "div",
          {
            class: _vm.tab == 1 ? "select" : "un-select",
            on: {
              click: function ($event) {
                return _vm.clickTab(1)
              },
            },
          },
          [_vm._v("\n      我的预约\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: _vm.tab == 2 ? "select" : "un-select",
            on: {
              click: function ($event) {
                return _vm.clickTab(2)
              },
            },
          },
          [_vm._v("\n      我的咨询\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { "flex-grow": "1" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "light-grey",
            on: {
              click: function ($event) {
                _vm.dialogVisible = true
              },
            },
          },
          [_vm._v("\n      预约说明\n    ")]
        ),
      ]),
      _vm._v(" "),
      _vm.tab == 1
        ? _vm._l(_vm.appointmentList, function (item, key) {
            return _c(
              "div",
              { key: key, staticClass: "appoint-card flex" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v("\n        " + _vm._s(item.__date) + "\n      "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    staticStyle: { "margin-top": "0.2rem" },
                  },
                  [
                    _c("div", { staticClass: "time" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.start) + "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "divider",
                      attrs: { src: "/static/images/kfsp/index/divider.png" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "time" }, [
                      _vm._v("\n          " + _vm._s(item.end) + "\n        "),
                    ]),
                  ]
                ),
                _vm._v(" "),
                item.status == 2 && !item.__overdue
                  ? [
                      _c(
                        "div",
                        { staticStyle: { position: "relative" } },
                        [
                          _c("img", {
                            staticClass: "bt",
                            attrs: {
                              src: _vm.getAppointmentStatus(item.status),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.goMiniprogram(
                                  item.roomId,
                                  item.orderId,
                                  item.userId,
                                  item.userName
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          !_vm.miniprogramEvn
                            ? _c(
                                "wx-open-launch-weapp",
                                {
                                  staticStyle: {
                                    width: "2.4rem",
                                    height: "0.88rem",
                                    overflow: "hidden",
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    opacity: "0",
                                  },
                                  attrs: {
                                    id: "launch-btn",
                                    username: "gh_c4a11dcfb812",
                                    path: _vm.getIndexPath(
                                      item.roomId,
                                      item.orderId
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "script",
                                    { attrs: { type: "text/wxtag-template" } },
                                    [
                                      _vm._v(
                                        '\n              <style type="text/css">.btn {\n              width: 240px;\n              height: 88px;\n              background: transparent;\n              }</style>\n              <div class="btn"></div>\n            '
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c("img", {
                        staticClass: "bt",
                        attrs: { src: _vm.getAppointmentStatus(item.status) },
                        on: {
                          click: function ($event) {
                            return _vm.onClickAppointmentStatus(item)
                          },
                        },
                      }),
                    ],
                _vm._v(" "),
                _c("div", { staticStyle: { "flex-grow": "1" } }),
                _vm._v(" "),
                item.__cancel_appoint
                  ? _c(
                      "div",
                      {
                        staticClass: "del-contain",
                        on: {
                          click: function ($event) {
                            return _vm.cancelAppointment(item)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "del",
                          attrs: {
                            src: "/static/images/kfsp/index/delete.png",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            )
          })
        : _vm.tab == 2
        ? _vm._l(_vm.consultList, function (item, key) {
            return _c("div", { key: key, staticClass: "consult-card" }, [
              _c("div", { staticClass: "top flex" }, [
                _c("div", [
                  _vm._v(_vm._s(item.date) + " " + _vm._s(item.start)),
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "flex-grow": "1" } }),
                _vm._v(" "),
                _vm.isNull(item.reportUrl)
                  ? _c("div", { staticClass: "desc" }, [
                      _vm._v(
                        "\n          请耐心等待康复报告            \n        "
                      ),
                    ])
                  : _c(
                      "div",
                      {
                        staticClass: "btn",
                        on: {
                          click: function ($event) {
                            return _vm.download(item.reportUrl)
                          },
                        },
                      },
                      [_vm._v("\n           查看报告\n        ")]
                    ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { height: "0.28rem" } }),
            ])
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "flex service" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: "/static/images/kfsp/index/service.png" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [
          _c("a", { attrs: { href: "tel:" + _vm.csTel } }, [
            _vm._v("联系客服"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "1rem" } }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "预约说明",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm._l(_vm.appointDesc, function (item, key) {
            return _c("div", { key: key, staticClass: "dialog-desc" }, [
              _vm._v("\n      " + _vm._s(item) + "\n    "),
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex dialog-confirm-bt",
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("\n      我已知晓\n    ")]
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }