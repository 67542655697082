var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "root" }, [
    _c("img", {
      staticClass: "bg",
      attrs: { src: "/static/images/hqb/detail/bg.png" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "label" }, [_vm._v("服务对象")]),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        _vm._v(
          "\n      在服务期间初次确诊重大疾病的被保险人，具体参照《重大疾病保险的疾病定义使用规范》（2020修订版）\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [_vm._v("服务权益")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [_vm._v("使用须知")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm.status == 0
        ? _c(
            "button",
            { staticClass: "active-bt flex", on: { click: _vm.activeConfirm } },
            [_vm._v("立刻激活")]
          )
        : _vm.status == 1
        ? _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              _c("button", { staticClass: "active-bt flex" }, [
                _vm._v("立刻使用"),
              ]),
              _vm._v(" "),
              _c(
                "wx-open-launch-weapp",
                {
                  staticClass: "weapp",
                  attrs: {
                    id: "launch-btn",
                    username: "gh_6e5133cbfdd6",
                    path: _vm.getPath(),
                  },
                },
                [
                  _c("script", { attrs: { type: "text/wxtag-template" } }, [
                    _vm._v(
                      '\n              <style type="text/css">.btn{width: 670px;height: 80px;background: transparent;}</style>\n                <div class="btn"></div>\n              '
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm.status == 2
        ? _c("button", { staticClass: "invalid-bt flex" }, [
            _vm._v("权益已失效"),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("li", [_vm._v("24小时全科医生视频咨询")]),
      _vm._v(" "),
      _c("li", [_vm._v("一键呼叫、不限次数")]),
      _vm._v(" "),
      _c("li", [_vm._v("咨询总结报告")]),
      _vm._v(" "),
      _c("li", [_vm._v("终身电子档案")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [
      _c("li", [
        _vm._v("请于2021年12月31日23点59分59秒前激活，逾期权益自动失效"),
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("激活后，有效期为1天（24小时）")]),
      _vm._v(" "),
      _c("li", [_vm._v("仅限本人使用")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }