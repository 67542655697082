import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import { setToken } from '@/utils/auth';
import wx from 'weixin-js-sdk';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      tab: 1,
      switchButtonIndex: 1,
      userOwnerships: null,
      consultationList: null,
      bugDrugOrderList: null,
      accessToken: null,
      HHMedicPlatformAccountId: null,
      profileName: null,
      errorMessage: null,
      loading: false,
      sdkProductId: 0
    };
  },
  created: function created() {
    setToken('');

    if (process.env.NODE_ENV === 'production') {
      this.profileName = 'prod';
    } else {
      this.profileName = 'test';
    } //console.log(this.$route)


    var nonce = this.getQuery('nonce');
    var pid = this.getQuery('pid');
    var sign = this.getQuery('sign');
    var timestamp = this.getQuery('timestamp');
    var uid = this.getQuery('uid');
    var authorization = this.getQuery('authorization');

    if (nonce && pid && sign && timestamp && uid) {
      this.uuidLogin(nonce, pid, sign, timestamp, uid);
    } else if (authorization) {
      setToken(authorization);
      this.accessToken = authorization;
      this.getUserOwnership();
      this.wxConfig();
    }

    if (sessionStorage.getItem('tab') != null) {
      this.tab = sessionStorage.getItem('tab');
      sessionStorage.removeItem('tab');
    }

    if (this.tab == 2) {
      this.getConsultation();
    } else if (this.tab == 3) {
      this.getBugDrugOrder();
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    rememberTab: function rememberTab() {
      sessionStorage.setItem('tab', this.tab);
    },
    consultationDetail: function consultationDetail(item) {
      //console.log(item)
      this.rememberTab();
      var itemString = JSON.stringify(item);
      this.$router.push({
        path: '/diagnose-detail?item=' + itemString
      });
    },
    goManual: function goManual(scroll) {
      this.rememberTab();
      var code = null;

      if (this.userOwnerships) {
        code = this.userOwnerships.code;
      }

      var title = null;

      if (this.userOwnerships) {
        title = this.userOwnerships.name;
      }

      code = code || 'HHM001';
      var path = '/manual?code=' + code;

      if (scroll) {
        path = path + '&scroll=' + scroll;
      }

      if (title) {
        path = path + '&title=' + title;
      }

      this.$router.push({
        path: path
      });
    },
    switchButton: function switchButton(switch_button) {
      this.switchButtonIndex = switch_button;
    },
    getIndexPath: function getIndexPath() {
      this.rememberTab();
      return "pages/openplatform/onboarding.html?access_token=" + this.accessToken;
    },
    getOrderPath: function getOrderPath(item) {
      this.rememberTab(); //this.HHMedicPlatformAccountId='E6E6E880BB3AD4A1D8B31FE763B6ADEB3F0D04F68EA2608F6783B874E4F50EEF'

      var orderPath = "pages/newIndex/newIndex.html?sdkProductId=" + this.sdkProductId + "&userToken=" + this.HHMedicPlatformAccountId + "&drugOrderId=" + item.drugOrderId + '&profileName=' + this.profileName; //console.log(orderPath)

      return orderPath;
    },
    getSwitchButtonClass: function getSwitchButtonClass(selected) {
      if (selected) {
        return 'select-button flex';
      }

      return 'normal-button flex';
    },
    clickTab: function clickTab(tab) {
      this.tab = tab;

      if (tab == 1) {
        this.getUserOwnership();
      } else if (tab == 2) {
        this.getConsultation();
      } else if (tab == 3) {
        this.getBugDrugOrder();
      }
    },
    getTabItemClass: function getTabItemClass(selected) {
      if (selected) {
        return 'select-item flex';
      }

      return 'normal-item flex';
    },
    getBottomLineClass: function getBottomLineClass(selected) {
      if (selected) {
        return 'select-bottom-line';
      }

      return 'normal-bottom-line';
    },
    wxConfig: function wxConfig() {
      var url = window.location;
      request({
        url: 'wechat-user/jsconfig?url=' + encodeURIComponent(url),
        method: 'get'
      }).then(function (res) {
        if (res.status === 200) {
          //console.log(res)
          var data = res.data.data;
          wx.config({
            debug: false,
            // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
            appId: data.appId,
            // 必填，公众号的唯一标识
            timestamp: data.timestamp,
            // 必填，生成签名的时间戳
            nonceStr: data.nonceStr,
            // 必填，生成签名的随机串
            signature: data.signature,
            // 必填，签名
            jsApiList: ['startRecord', 'stopRecord', 'onVoiceRecordEnd', 'playVoice', 'pauseVoice', 'stopVoice', 'onVoicePlayEnd', 'translateVoice', 'onMenuShareTimeline', 'wx-open-launch-weapp'],
            // 必填，需要使用的JS接口列表
            openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']

          });
          wx.ready(function () {
            //console.log('-----ready-----')
            //console.log(arguments)
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
            var btn = document.getElementById('launch-btn'); // launch 用户点击跳转按钮并对确认弹窗进行操作后触发

            if (btn) {
              btn.addEventListener('launch', function (e) {
                console.log('success');
              });
            } // error 用户点击跳转按钮后出现错误


            if (btn) {
              btn.addEventListener('error', function (e) {
                console.log('fail', e.detail);
              });
            }
          });
          wx.error(function (res) {
            console.log('-----error-----'); // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
          });
        }
      });
    },

    /**
     * 我的问诊
     */
    getConsultation: function getConsultation() {
      var _this = this;

      this.loading = true;
      request({
        url: 'user-videodoctor/consultation/list',
        method: 'post',
        data: {
          pageNumber: 1,
          pageSize: 1000
        }
      }).then(function (res) {
        if (res.status === 200) {
          _this.consultationList = res.data.data.data;

          if (_this.consultationList) {
            _this.consultationList.forEach(function (item) {
              item.__createTime = new Date(parseFloat(item.createTime)).format('yyyy-MM-dd HH:mm');
            });
          }
        } else {
          _this.errorMessage = res.data.errorMessage;
        }

        _this.loading = false;
      });
    },

    /**
     * 我的配药
     */
    getBugDrugOrder: function getBugDrugOrder() {
      var _this2 = this;

      this.loading = true;
      request({
        url: 'user-videodoctor/bug-drug-order/list',
        method: 'post',
        data: {}
      }).then(function (res) {
        if (res.status === 200) {
          //console.log(res)
          _this2.bugDrugOrderList = res.data.data; // this.bugDrugOrder1List.forEach(function (item) {
          //   item.__createTime = new Date(parseFloat(item.createTime)).format('yyyy-MM-dd HH:mm')
          // })
        } else {
          _this2.errorMessage = res.data.errorMessage;
        }

        _this2.loading = false;
      });
    },
    getUserOwnership: function getUserOwnership() {
      var _this3 = this;

      this.loading = true;
      request({
        url: 'user-videodoctor/owner-ship',
        method: 'get'
      }).then(function (res) {
        if (res.status === 200) {
          _this3.userOwnerships = res.data.data;
          var code = _this3.userOwnerships.code;

          if (!code || !_this3.userOwnerships.isActivate) {
            _this3.errorMessage = 'user_ownership的code为空，或者is_activate=0'; // this.$router.push({
            //   path: '/no-right',
            //   replace:true,
            // })

            return;
          }

          var activeTime = _this3.userOwnerships.activeTime;
          var expireTime = _this3.userOwnerships.expireTime;
          var __time = '';

          if (activeTime) {
            __time += activeTime.replace(/T.*$/img, '').replace(/-/g, '/');
          }

          if (expireTime) {
            __time += ' ~ ' + expireTime.replace(/T.*$/img, '').replace(/-/g, '/');
          }

          _this3.userOwnerships.__time = __time;
          _this3.userOwnerships.__availableCoupon = [];
          _this3.userOwnerships.__usedCoupon = [];
          var usedTimes = _this3.userOwnerships.usedTimes;
          var remainingTimes = _this3.userOwnerships.remainingTimes;
          _this3.userOwnerships.__availableCoupon.length = Math.max(remainingTimes, 0);
          _this3.userOwnerships.__usedCoupon.length = Math.abs(usedTimes) || 0;
          _this3.sdkProductId = _this3.userOwnerships.sdkProductId;
        } else {
          _this3.errorMessage = res.data.errorMessage;
        }

        _this3.loading = false;
      });
      this.getPlatformIdentity();
    },
    getPlatformIdentity: function getPlatformIdentity() {
      var _this4 = this;

      request({
        url: 'user/platform-identity',
        method: 'post'
      }).then(function (res) {
        if (res.status === 200) {
          var identityList = res.data.data.identityList;

          if (identityList) {
            var HHMedicIdentity = identityList.filter(function (item) {
              return item.platformType == 'HHMedic';
            });

            if (!isNull(HHMedicIdentity)) {
              _this4.HHMedicPlatformAccountId = HHMedicIdentity[0].platformAccountId;
            } else {
              _this4.errorMessage = 'identityList里没有platformType==HHMedic的记录'; // this.$router.push({
              //   path: '/no-right',
              //   replace:true,
              // })
            }
          }
        } else {
          _this4.errorMessage = res.data.errorMessage;
        }
      });
    },
    uuidLogin: function uuidLogin(nonce, pid, sign, timestamp, uuid) {
      var _this5 = this;

      request({
        url: 'user/uuid-login',
        method: 'get',
        params: {
          nonce: nonce,
          pid: pid,
          sign: sign,
          timestamp: timestamp,
          uuid: uuid
        }
      }).then(function (res) {
        if (res.status === 200) {
          var token = res.data.data.usertoken;
          _this5.accessToken = token;
          setToken(token);

          _this5.getUserOwnership();

          _this5.wxConfig();
        } else {
          _this5.errorMessage = res.data.errorMessage;
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
};