//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      score: 4,
      comment: null
    };
  },
  created: function created() {
    this.orderId = this.getQuery('orderId');
  },
  computed: {},
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    star: function star(v) {
      this.score = v;
    },
    getStarImg: function getStarImg(v, target) {
      if (v >= target) {
        return "/static/images/kfsp/comment/star_select.png";
      }

      return "/static/images/kfsp/comment/star_unselect.png";
    },
    submitComment: function submitComment() {
      var _this = this;

      request({
        url: 'service-order/comment',
        method: 'post',
        data: {
          orderId: this.orderId,
          score: this.score,
          comment: this.comment
        }
      }).then(function (res) {
        console.log(res);
        _this.selectTimeItem = null;

        _this.$confirm('提交成功', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success'
        }).then(function () {
          _this.$router.go(-1);
        }).catch(function () {});
      });
    }
  }
};