var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.userOwnerships && _vm.userOwnerships.code
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
          },
          [
            _c("div", { staticStyle: { position: "relative" } }, [
              _c("img", {
                staticClass: "header-bg",
                attrs: { src: "/static/images/index/header.png" },
              }),
              _vm._v(" "),
              _vm.userOwnerships && _vm.userOwnerships.code
                ? _c(
                    "div",
                    {
                      staticClass: "header-bg-bt flex",
                      on: {
                        click: function ($event) {
                          return _vm.goManual(0)
                        },
                      },
                    },
                    [_vm._v("\n      使用手册\n    ")]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex tab-root" }, [
              _c(
                "div",
                {
                  staticClass: "select-item flex",
                  on: {
                    click: function ($event) {
                      return _vm.clickTab(1)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: _vm.getTabItemClass(_vm.tab == 1),
                      staticStyle: { "flex-direction": "column" },
                    },
                    [
                      _c("div", [_vm._v("\n          我的权益\n        ")]),
                      _vm._v(" "),
                      _c("div", {
                        class: _vm.getBottomLineClass(_vm.tab == 1),
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: _vm.getTabItemClass(_vm.tab == 2),
                  on: {
                    click: function ($event) {
                      return _vm.clickTab(2)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "flex-direction": "column" },
                    },
                    [
                      _c("div", [_vm._v("\n          我的问诊\n        ")]),
                      _vm._v(" "),
                      _c("div", {
                        class: _vm.getBottomLineClass(_vm.tab == 2),
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: _vm.getTabItemClass(_vm.tab == 3),
                  on: {
                    click: function ($event) {
                      return _vm.clickTab(3)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "flex-direction": "column" },
                    },
                    [
                      _c("div", [_vm._v("\n          我的配药\n        ")]),
                      _vm._v(" "),
                      _c("div", {
                        class: _vm.getBottomLineClass(_vm.tab == 3),
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tab == 1,
                    expression: "tab == 1",
                  },
                ],
                staticClass: "flex-column",
              },
              [
                [
                  _c(
                    "div",
                    { staticClass: "right-card" },
                    [
                      _c("div", { staticClass: "right" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.userOwnerships.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "date" }, [
                          _vm._v(
                            "有效期：" + _vm._s(_vm.userOwnerships.__time)
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.userOwnerships && _vm.userOwnerships.isOverdue
                        ? [
                            _c("img", {
                              staticClass: "card-disabled",
                              attrs: {
                                src: "/static/images/index/card_disabled.png",
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "wx-open-launch-weapp",
                        {
                          staticStyle: {
                            width: "6.7rem",
                            height: "4.2rem",
                            overflow: "hidden",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            opacity: "0",
                          },
                          attrs: {
                            id: "launch-btn",
                            username: "gh_95e7300edf4c",
                            path: _vm.getIndexPath(),
                          },
                        },
                        [
                          _c(
                            "script",
                            { attrs: { type: "text/wxtag-template" } },
                            [
                              _vm._v(
                                '\n              <style type="text/css">.btn{width: 670px;height: 420px;background: transparent;}</style>\n                <div class="btn"></div>\n              '
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.userOwnerships && _vm.userOwnerships.totalTimes > 0
                    ? [
                        _c("div", {
                          staticClass: "dispense-medicines-card-label",
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex" }, [
                          _c(
                            "div",
                            { staticClass: "switch-button-root flex" },
                            [
                              _c(
                                "div",
                                {
                                  class: _vm.getSwitchButtonClass(
                                    _vm.switchButtonIndex == 1
                                  ),
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchButton(1)
                                    },
                                  },
                                },
                                [_vm._v("生效中")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: _vm.getSwitchButtonClass(
                                    _vm.switchButtonIndex == 2
                                  ),
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchButton(2)
                                    },
                                  },
                                },
                                [_vm._v("已失效")]
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.switchButtonIndex == 1
                          ? [
                              _vm.userOwnerships &&
                              _vm.userOwnerships.__availableCoupon &&
                              _vm.userOwnerships.__availableCoupon.length > 0
                                ? _vm._l(
                                    _vm.userOwnerships.__availableCoupon,
                                    function (item, key) {
                                      return _c(
                                        "div",
                                        {
                                          key: key,
                                          staticClass:
                                            "medicine-package-normal",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "amount-contain" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "rmb" },
                                                [_vm._v("¥")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "amount" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.userOwnerships
                                                        .maxAmountPerTime
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "wx-open-launch-weapp",
                                            {
                                              staticStyle: {
                                                width: "6.74rem",
                                                height: "2.44rem",
                                                overflow: "hidden",
                                                position: "absolute",
                                                top: "0",
                                                left: "0",
                                                opacity: "0",
                                              },
                                              attrs: {
                                                id: "launch-btn",
                                                username: "gh_95e7300edf4c",
                                                path: _vm.getIndexPath(),
                                              },
                                            },
                                            [
                                              _c(
                                                "script",
                                                {
                                                  attrs: {
                                                    type: "text/wxtag-template",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    '\n                    <style type="text/css">.btn{width: 674px;height: 244px;background: transparent;}</style>\n                      <div class="btn"></div>\n                  '
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "detail-bt flex",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goManual(1)
                                                },
                                              },
                                            },
                                            [_vm._v("详情")]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm.userOwnerships &&
                                  _vm.userOwnerships.__availableCoupon &&
                                  _vm.userOwnerships.__availableCoupon.length ==
                                    0
                                ? [
                                    _c("img", {
                                      staticClass: "empty-data-img",
                                      attrs: {
                                        src: "/static/images/index/empty-data.png",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "empty-data flex" },
                                      [_vm._v("暂无数据")]
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.switchButtonIndex == 2
                          ? [
                              _vm.userOwnerships &&
                              _vm.userOwnerships.__usedCoupon &&
                              _vm.userOwnerships.__usedCoupon.length > 0
                                ? _vm._l(
                                    _vm.userOwnerships.__usedCoupon,
                                    function (item, key) {
                                      return _c(
                                        "div",
                                        {
                                          key: key,
                                          staticClass:
                                            "medicine-package-disabled",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "amount-contain" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "rmb" },
                                                [_vm._v("¥")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "amount" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.userOwnerships
                                                        .maxAmountPerTime
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  )
                                : _vm.userOwnerships &&
                                  _vm.userOwnerships.__usedCoupon &&
                                  _vm.userOwnerships.__usedCoupon.length == 0
                                ? [
                                    _c("img", {
                                      staticClass: "empty-data-img",
                                      attrs: {
                                        src: "/static/images/index/empty-data.png",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "empty-data flex" },
                                      [_vm._v("暂无数据")]
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                _vm._v(" "),
                _c("div", { staticStyle: { height: "0.4rem" } }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tab == 2,
                    expression: "tab == 2",
                  },
                ],
                staticClass: "flex-column",
              },
              [
                _vm.consultationList && _vm.consultationList.length > 0
                  ? _vm._l(_vm.consultationList, function (item, key) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass: "diagnose-card",
                          on: {
                            click: function ($event) {
                              return _vm.consultationDetail(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "title-row flex" }, [
                            _c("div", { staticClass: "date" }, [
                              _vm._v(_vm._s(item.__createTime)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(item.doctorDept) +
                                  " | " +
                                  _vm._s(item.doctorName)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "person-root" }, [
                            _c("span", [_vm._v(_vm._s(item.name))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "light-grey" }, [
                              _vm._v(_vm._s(item.sex)),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "light-grey" }, [
                              _vm._v(_vm._s(item.age)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "desc" }, [
                            _vm._v(
                              "\n          主诉：" +
                                _vm._s(item.mainSuit) +
                                "\n        "
                            ),
                          ]),
                        ]
                      )
                    })
                  : [
                      _c("img", {
                        staticClass: "empty-data-img",
                        attrs: { src: "/static/images/index/empty-data.png" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "empty-data flex" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ],
                _vm._v(" "),
                _c("div", { staticStyle: { height: "0.2rem" } }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tab == 3,
                    expression: "tab == 3",
                  },
                ],
                staticClass: "flex-column",
              },
              [
                _vm.bugDrugOrderList && _vm.bugDrugOrderList.length > 0
                  ? _vm._l(_vm.bugDrugOrderList, function (item, key1) {
                      return _c(
                        "div",
                        { key: key1, staticClass: "dispense-medicines-card" },
                        [
                          _c(
                            "div",
                            { staticClass: "title-row-root" },
                            _vm._l(
                              item.medications,
                              function (bugDrugOrderItem, key2) {
                                return _c(
                                  "div",
                                  { key: key2, staticClass: "title-row flex" },
                                  [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(bugDrugOrderItem.name)),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "x" + _vm._s(bugDrugOrderItem.count)
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "desc" }, [
                            _vm._v(
                              "\n          问诊编号：" +
                                _vm._s(item.callOrderId) +
                                "\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "desc",
                              staticStyle: { "padding-bottom": "0.25rem" },
                            },
                            [
                              _vm._v(
                                "\n          问诊时间：" +
                                  _vm._s(item.createTime.substr(0, 16)) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          item.medications && item.medications.length > 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "wx-open-launch-weapp",
                                    {
                                      staticStyle: {
                                        width: "6.7rem",
                                        height: "100%",
                                        overflow: "hidden",
                                        position: "absolute",
                                        top: "0",
                                        left: "0",
                                        opacity: "0",
                                        "z-index": "99",
                                      },
                                      attrs: {
                                        id: "launch-btn",
                                        username: "gh_95e7300edf4c",
                                        path: _vm.getOrderPath(item),
                                      },
                                    },
                                    [
                                      _c(
                                        "script",
                                        {
                                          attrs: {
                                            type: "text/wxtag-template",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            '\n              <style type="text/css">.btn{width: 670px;height: 600px;background: transparent;}</style>\n                <div class="btn"></div>\n            '
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    })
                  : [
                      _c("img", {
                        staticClass: "empty-data-img",
                        attrs: { src: "/static/images/index/empty-data.png" },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "empty-data flex" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ],
                _vm._v(" "),
                _c("div", { staticStyle: { height: "0.2rem" } }),
              ],
              2
            ),
          ]
        )
      : _c("div", [
          _c("div", { staticStyle: { height: "4rem" } }),
          _vm._v(" "),
          !_vm.errorMessage
            ? _c("div", { staticClass: "information flex" }, [
                _vm._v("请稍等，页面加载中..."),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessage
            ? _c("div", [_vm._v(" " + _vm._s(_vm.errorMessage))])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }