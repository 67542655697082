import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      name: null,
      idCardNo: null,
      mobile: null,
      verificationCode: null,
      reportImages: [],
      idCardImages: [],
      clientId: null,
      projectId: null,
      ownershipId: null
    };
  },
  created: function created() {
    console.log(this.$route);
    console.log(this.isHqb() + "," + this.isYnhl());
    this.initCommonParam();
    console.log(this.clientId + "," + this.projectId + "," + this.ownershipId);
  },
  computed: {
    imgUploadUrl: function imgUploadUrl() {
      return process.env.VUE_APP_BASE_FILE + 'upload';
    },
    images: function images() {
      return this.reportImages.concat({
        id: -1,
        src: "/static/images/ic_capture_add.png"
      });
    },
    IdImages: function IdImages() {
      return this.idCardImages.concat({
        id: -1,
        src: "/static/images/ic_capture_add.png"
      });
    },
    idCardImgsList: function idCardImgsList() {
      if (this.isNull(this.idCardImages)) {
        return [];
      }

      var list = [];
      this.idCardImages.forEach(function (item) {
        list.push({
          url: getImage(item),
          uuid: item
        });
      });
      return list;
    },
    reportImgsList: function reportImgsList() {
      if (this.isNull(this.reportImages)) {
        return [];
      }

      var list = [];
      this.reportImages.forEach(function (item) {
        list.push({
          url: getImage(item),
          uuid: item
        });
      });
      return list;
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    check: function check() {
      if (this.isNull(this.name)) {
        return '请输入被保人姓名';
      }

      if (this.isNull(this.idCardNo)) {
        return '请输入被保人身份证';
      }

      if (this.idCardNo.length != 18) {
        return '身份证应为18位';
      }

      if (this.isNull(this.mobile)) {
        return '请输入被保人手机号';
      }

      if (!/^\d{11}$/img.test(this.mobile)) {
        return '手机号应为11位数字';
      }

      if (this.isNull(this.reportImages)) {
        return '请上传病理报告&出院小结/住院报告';
      }

      if (this.isNull(this.idCardImages)) {
        return '请上传身份证照片';
      }
    },
    submit: function submit() {
      var _this = this;

      var warn = this.check();

      if (!this.isNull(warn)) {
        this.$message.error(warn);
        return;
      }

      request({
        url: 'ownership/audit/submit',
        method: 'post',
        data: {
          clientId: this.clientId,
          projectId: this.projectId,
          ownershipId: this.ownershipId,
          mobile: this.mobile,
          idCardNo: this.idCardNo,
          username: this.name,
          idCardImages: this.idCardImages,
          reportImages: this.reportImages
        }
      }).then(function (res) {
        //console.log(res)
        if (res.status === 200) {
          _this.$router.push({
            path: '/kfsp/audit-pending'
          });
        }
      });
    },
    beforeImgUpload: function beforeImgUpload(file) {
      var fileFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
      var limitSize = true;

      if (file.size > 20 * 1024 * 1024) {
        limitSize = false;
      }

      if (!limitSize) {
        this.$message.error('上传图片大小不能超过5M!');
      }

      if (!fileFormat) {
        this.$message.error('上传图片只能是 JPG、PNG格式!');
      }

      return fileFormat && limitSize;
    },
    uploadReportImgSuccess: function uploadReportImgSuccess(response, file, fileList) {
      this.uploadImgSuccess.call(this, arguments[0], arguments[1], arguments[2], this.reportImages);
    },
    uploadIdCardImgSuccess: function uploadIdCardImgSuccess() {
      this.uploadImgSuccess.call(this, arguments[0], arguments[1], arguments[2], this.idCardImages);
    },
    uploadImgSuccess: function uploadImgSuccess(response, file, fileList, imgs) {
      console.log(arguments);

      if (this.isNull(response)) {
        return;
      }

      var uuid = response.data.uuid;
      file.uuid = uuid;
      imgs.push(uuid);
    },
    uploadReportImgRemove: function uploadReportImgRemove(file, fileList) {
      console.log(arguments);
      this.reportImages.remove(file.uuid);
      console.log(this.reportImages);
    },
    uploadIdCardImgRemove: function uploadIdCardImgRemove(file, fileList) {
      console.log(arguments);
      this.idCardImages.remove(file.uuid);
      console.log(this.idCardImages);
    }
  }
};