var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "0.32rem 0.24rem 1.8rem 0.24rem" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [_vm._v("被保人姓名")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name",
          },
        ],
        staticClass: "input",
        attrs: { type: "text" },
        domProps: { value: _vm.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.name = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [_vm._v("被保人手机号")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.mobile,
            expression: "mobile",
          },
        ],
        staticClass: "input",
        attrs: { type: "text" },
        domProps: { value: _vm.mobile },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.mobile = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [_vm._v("被保人身份证号")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.idCardNo,
            expression: "idCardNo",
          },
        ],
        staticClass: "input",
        attrs: { type: "text", placeholder: "请输入" },
        domProps: { value: _vm.idCardNo },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.idCardNo = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [
        _vm._v("身份证照片（您可以上传2张照片）"),
      ]),
      _vm._v(" "),
      _c(
        "el-upload",
        {
          staticClass: "img",
          attrs: {
            "before-upload": _vm.beforeImgUpload,
            "on-success": _vm.uploadIdCardImgSuccess,
            "on-remove": _vm.uploadIdCardImgRemove,
            action: _vm.imgUploadUrl,
            limit: 2,
            "file-list": _vm.idCardImgsList,
            "list-type": "picture-card",
            multiple: "",
          },
        },
        [
          _c("i", { staticClass: "el-icon-plus" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v("只能上传jpeg/jpg/png文件")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [
        _vm._v("病理报告&出院小结/住院报告（您可以上传10张照片）"),
      ]),
      _vm._v(" "),
      _c(
        "el-upload",
        {
          staticClass: "img",
          attrs: {
            "before-upload": _vm.beforeImgUpload,
            "on-success": _vm.uploadReportImgSuccess,
            "on-remove": _vm.uploadReportImgRemove,
            action: _vm.imgUploadUrl,
            limit: 10,
            "file-list": _vm.reportImgsList,
            "list-type": "picture-card",
            multiple: "",
          },
        },
        [
          _c("i", { staticClass: "el-icon-plus" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v("只能上传jpeg/jpg/png文件，且不超过 20 M；")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "1rem" } }),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "submit-bt flex", on: { click: _vm.submit } },
        [_vm._v("提交审核")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "info-tip", staticStyle: { "margin-top": "0.2rem" } },
      [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "title" }, [_vm._v("服务须知")]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "desc",
            staticStyle: { margin: "0.24rem 0.06rem 0 0.06rem" },
          },
          [
            _vm._v(
              "\n        1.本服务适用于服务期间初次确诊重大疾病的被保险人，具体参照《重大疾病保险的疾病定义使用规范》（2020修订版）\n      "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "desc",
            staticStyle: { margin: "0rem 0.06rem 0 0.06rem" },
          },
          [
            _vm._v(
              "\n        2.使用服务前，请如实填写以下信息，我们将根据您提交的信息判断是否符合健康服务的标准！\n      "
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }