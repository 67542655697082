//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      calendarValue: null,
      selectDate: new Date().format('yyyy-MM-dd'),
      reservedList: [],
      selectTimeItem: null
    };
  },
  created: function created() {
    this.initQuery();
    this.getReservedStatus();
  },
  computed: {},
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    onTimesChange: function onTimesChange(item) {
      console.log(item);

      if (item.status == 1) {
        return;
      }

      this.selectTimeItem = item;
    },
    onCalendarChange: function onCalendarChange(day) {
      console.log(arguments);
      this.selectTimeItem = null;
      var today = new Date().format('yyyy-MM-dd');
      var warning = null;

      if (string2date(day) < string2date(today)) {
        warning = "日期选择有误";
      }

      if (!warning) {
        if (string2date(day) > string2date(today).addDays(60)) {
          warning = "日期选择有误";
        }
      }

      if (warning) {
        this.$message.warning(warning);
        this.selectDate = null;
        this.reservedList = null;
      } else {
        this.selectDate = day;
        this.getReservedStatus();
      }
    },
    getReservedClass: function getReservedClass(item, selectTimeItem) {
      var status = item.status;
      if (status == 1) return 'time-card-full flex';

      if (status == 0) {
        var start = item.start;
        var end = item.end;

        if (selectTimeItem && selectTimeItem.start == start && selectTimeItem.end == end) {
          return 'time-card-select flex';
        }

        return 'time-card-normal flex';
      }
    },
    appointment: function appointment() {
      var _this = this;

      if (!this.selectTimeItem) {
        sage.warning("请选择预约时间段");
        return;
      }

      this.$confirm('是否要预约[' + this.selectDate + " " + this.selectTimeItem.start + "~" + this.selectTimeItem.end + ']?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否'
      }).then(function () {
        _this.__appointment();
      }).catch(function () {});
    },
    __appointment: function __appointment() {
      var _this2 = this;

      request({
        url: 'appointment',
        method: 'post',
        data: {
          clientId: this.clientId,
          projectId: this.projectId,
          ownershipId: this.ownershipId,
          date: this.selectDate,
          start: this.selectTimeItem.start,
          end: this.selectTimeItem.end,
          type: "ONLINE_REHABILITATION_CONSULTANT"
        }
      }).then(function (res) {
        console.log(res);
        _this2.selectTimeItem = null;

        _this2.$confirm('预约成功', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'success'
        }).then(function () {
          _this2.$router.go(-1);
        }).catch(function () {});
      });
    },
    getReservedStatus: function getReservedStatus() {
      var _this3 = this;

      request({
        url: 'appointment/reserved-status',
        method: 'post',
        data: {
          clientId: this.clientId,
          projectId: this.projectId,
          ownershipId: this.ownershipId,
          date: this.selectDate,
          type: "ONLINE_REHABILITATION_CONSULTANT"
        }
      }).then(function (res) {
        console.log(res);
        _this3.reservedList = res.data.data.reservedList;
      });
    }
  }
};