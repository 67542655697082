import "core-js/modules/es6.string.sub";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from "@/utils/request";
import { getTomorrowString } from "@/utils/index";
var uploadUrl = process.env.VUE_APP_BASE_FILE + "upload";
export default {
  components: {},
  data: function data() {
    return {
      menuItems: [{
        name: "确定"
      }],
      isVisible: false,
      baseUrl: process.env.VUE_APP_BASE_API.replace("center/api", "api/v2"),
      drawer: false,
      dialogVisible: false,
      gender: null,
      bodyStatusName: null,
      age: null,
      area: null,
      address: null,
      comment: null,
      bodyStatusVisible: false,
      bodyStatusList: [[{
        label: 0,
        value: "女"
      }, {
        label: 1,
        value: "男"
      }]],
      timePickerVisible: false,
      url: uploadUrl,
      fileList: [],
      materialImages: [],
      showAddress: false,
      province: [],
      // 省
      city: [],
      // 市
      country: [],
      // 县
      town: [],
      cityCode: "",
      date: "",
      start: "",
      allArea: [],
      title: "",
      iconUrl: "",
      originalPrice: "",
      userName: "",
      phone: "",
      startDate: ""
    };
  },
  created: function created() {
    var _this2 = this;

    request({
      url: "".concat(this.baseUrl, "china-area-code/get")
    }).then(function (res) {
      if (res.status === 200) {
        _this2.allArea = res.data.data;
        _this2.province = _this2.addId(res.data.data);
      }
    });
    request({
      url: "".concat(this.baseUrl, "user/get-by-token")
    }).then(function (res) {
      if (res.status === 200) {
        _this2.userName = res.data.data.name;
        _this2.phone = res.data.data.mobile;
      }
    });
    var _this$$route$query = this.$route.query,
        title = _this$$route$query.title,
        originalPrice = _this$$route$query.originalPrice;
    this.title = "幽门螺旋杆菌、便隐血双检";
    this.iconUrl = 'https://test-dashu-pimh-mini-apps-resource-1303893949.cos.ap-shanghai.myqcloud.com/assets/icon/hp.jpg';
    this.originalPrice = 666;
    this.startDate = getTomorrowString() + " 00:00";
  },
  methods: {
    makeSureSubmit: function makeSureSubmit(itemParams) {
      console.log(itemParams);

      if (itemParams.name === "确定") {
        this.requestOrder();
      }
    },
    goDetail: function goDetail() {
      var _this = this;

      this.$router.push({
        path: "/home-self-checking/detail",
        query: {
          clientId: _this.$route.query.clientId,
          projectId: _this.$route.query.projectId,
          ownershipId: _this.$route.query.ownershipId,
          ownershipGroupId: _this.$route.query.ownershipGroupId,
          orderType: _this.$route.query.orderType
        }
      });
    },
    addId: function addId(list) {
      return list.map(function (item) {
        item.id = item.code;
        return item;
      });
    },
    onChange1: function onChange1(cal) {
      // 判断下一个行政区列表的内容是否有值
      if (cal.custom === "province") {
        this.city = this.addId(cal.value.sub);
      }

      if (cal.custom === "city") {
        this.country = this.addId(cal.value.sub);
      }

      if (cal.custom === "country") {
        this.town = this.addId(cal.value.sub);
      }

      if (!cal.value.sub || cal.value.sub.length < 1) {
        this.showAddress = false;
      }
    },
    close1: function close1(val) {
      this.area = val.data.addressStr;
      this.cityCode = val.data.town.code;
    },
    openSwitch: function openSwitch(param) {
      this["".concat(param)] = true;
    },
    closeSwitch: function closeSwitch(param) {
      this["".concat(param)] = false;
    },
    confirm: function confirm(chooseData) {
      console.log(chooseData);
      this.bodyStatusName = chooseData[0].value;
      this.gender = chooseData[0].label;
      this.closeSwitch("bodyStatusVisible");
    },
    switchActionSheet: function switchActionSheet() {
      this.isVisible = !this.isVisible;
    },
    submitOrder: function submitOrder() {
      var fieldsList = [{
        name: "性别",
        value: this.gender
      }, {
        name: "年龄",
        value: this.age
      }, {
        name: "所在地区",
        value: this.cityCode
      }, {
        name: "详细地址",
        value: this.address
      } // {
      //   name: "备注",
      //   value: this.comment
      // }
      ];
      var infoError = false;

      for (var _i = 0, _fieldsList = fieldsList; _i < _fieldsList.length; _i++) {
        var item = _fieldsList[_i];

        if (Array.isArray(item.value)) {
          if (item.value.length === 0) {
            this.$notify.text("".concat(item.name, "\u4E0D\u80FD\u4E3A\u7A7A"), {
              duration: 2000
            });
            infoError = true;
            break;
          }
        } else {
          if (!item.value && item.value !== 0) {
            this.$notify.text("".concat(item.name, "\u4E0D\u80FD\u4E3A\u7A7A"), {
              duration: 2000
            });
            infoError = true;
            break;
          }
        }
      } // fieldsList.forEach(item=>{
      //   if(Array.isArray(item.value)){
      //     if(item.value.length===0){
      //       this.$notify.text(`${item.name}不能为空`, {
      //         duration: 2000
      //       });
      //       infoError=true
      //     }
      //   }else{
      //     if(!item.value){
      //       this.$notify.text(`${item.name}不能为空`, {
      //         duration: 2000
      //       });
      //       infoError=true
      //     }
      //   }
      // })


      if (infoError) {
        return;
      }

      this.switchActionSheet();
    },
    requestOrder: function requestOrder() {
      var _this3 = this;

      var _this$$route$query2 = this.$route.query,
          clientId = _this$$route$query2.clientId,
          projectId = _this$$route$query2.projectId,
          ownershipId = _this$$route$query2.ownershipId,
          ownershipGroupId = _this$$route$query2.ownershipGroupId;
      request({
        url: "gene-testing/submit",
        method: "post",
        data: {
          clientId: clientId,
          projectId: projectId,
          ownershipId: ownershipId,
          ownershipGroupId: ownershipGroupId,
          name: this.userName,
          gender: this.gender,
          orderType: "HOME_SELF_CHECKING",
          age: this.age,
          tel: this.phone,
          cityCode: this.cityCode,
          address: this.area + this.address,
          comment: this.comment
        }
      }).then(function (res) {
        if (res.status === 200) {
          _this3.$dialog.closed();

          _this3.$router.push({
            path: "/home-self-checking/self-checking-info?clientId=".concat(clientId, "&projectId=").concat(projectId, "&ownershipId=").concat(ownershipId, "&ownershipGroupId=").concat(ownershipGroupId)
          });
        }
      });
    }
  }
};