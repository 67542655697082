//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import { setToken } from '@/utils/auth';
import wx from 'weixin-js-sdk';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      tab: 1,
      package1: []
    };
  },
  created: function created() {
    var code = this.getQuery('code');

    if (code == 'HHM001' || code == 'HHM002') {
      this.package1.push({
        src: '/static/images/manual/package1/img1.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img2.png',
        title: this.getTitle()
      });

      if (code == 'HHM002') {
        this.package1.push({
          src: '/static/images/manual/package1/img2_2.png'
        });
      }

      this.package1.push({
        src: '/static/images/manual/package1/img2_3.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img4.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img5.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img6.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img7.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img8.png'
      });
    } else if (code == 'HHM003') {
      this.package1.push({
        src: '/static/images/manual/package1/img1.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img2.png',
        title: this.getTitle()
      });
      this.package1.push({
        src: '/static/images/manual/package1/img2_3.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/limit_300.png',
        id: 'limit'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img4.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img5.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img6.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img7.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img8.png'
      });
    } else if (code == 'HHM004') {
      this.package1.push({
        src: '/static/images/manual/package1/img1.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img2.png',
        title: this.getTitle()
      });
      this.package1.push({
        src: '/static/images/manual/package1/img2_3.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/limit_600.png',
        id: 'limit'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img4.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img5.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img6.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img7.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img8.png'
      });
    } else if (code == 'HHM005') {
      this.package1.push({
        src: '/static/images/manual/package1/img1.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img2.png',
        title: this.getTitle()
      });
      this.package1.push({
        src: '/static/images/manual/package1/img2_3.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/limit_1000.png',
        id: 'limit'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img4.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img5.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img6.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img7.png'
      });
      this.package1.push({
        src: '/static/images/manual/package1/img8.png'
      });
    }

    var __this = this;

    setTimeout(function () {
      var scroll = __this.getQuery("scroll");

      if (scroll) {
        var limit = document.getElementById('limit');

        if (limit) {
          limit.scrollIntoView();
        }
      }
    }, 500);
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    getTitle: function getTitle() {
      return this.getQuery('title') || '视频医生年卡';
    }
  }
};