var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "serviceDetail" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "serviceInfo" }, [
      _c("div", { staticClass: "serviceContent" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "contentInfo" }, [
          _c("div", [_vm._v("服务名称： " + _vm._s(_vm.title))]),
          _vm._v(" "),
          _c("div", [_vm._v("服务次数：1")]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n          服务对象：罹患重疾且确认可理赔的被保险人\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("\n          服务范围:\n        ")]),
          _vm._v(" "),
          _vm._m(2),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _vm.buttonShow
        ? _c("div", { staticClass: "appointment", on: { click: _vm.goInfo } }, [
            _vm._v("\n      立即预约\n    "),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: "https://test-dashu-pimh-mini-apps-resource-1303893949.cos.ap-shanghai.myqcloud.com/assets/images/service_detail_01.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "blockTop" }, [
      _c("div", { staticClass: "titleLeft" }),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n          服务内容\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(
        "\n          1、生活护理：单人二十四小时看护；饮食护理；整理床单；翻身拍背；擦浴；会阴护理等生活护理；"
      ),
      _c("br"),
      _vm._v(
        "\n          2、支持护理：身体状况观测；按时喂药；心率，血压，血糖等体征测量；室内空气、衣物消毒；户外助行；"
      ),
      _c("br"),
      _vm._v(
        "\n          3、康复训练(部分适合护理员执行) ：生活自理能力康复训练，关节功能训练及部分适合在远程康复指导师指导下适合护理员执行项目。\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "serviceFlow" }, [
      _c("div", { staticClass: "blockTop" }, [
        _c("div", { staticClass: "titleLeft" }),
        _vm._v(" "),
        _c("div", { staticClass: "title" }, [
          _vm._v("\n          服务流程\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "contentInfo" }, [
        _c("div", [
          _vm._v(
            "1、服务预约：找到相关康复权益，并提交预约订单，等待后台审核。"
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("2、服务确认：客服人员24小时内回电，确认服务内容。"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "3、上门服务：康护人员上门前1小时再次电话确认服务订单，并按照约定的时间完成服务内容。"
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "4、服务完成：线下服务完成后，服务人员引导用户确认井提交评价，完成订单。"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip" }, [
      _c("div", { staticClass: "blockTop" }, [
        _c("div", { staticClass: "titleLeft" }),
        _vm._v(" "),
        _c("div", { staticClass: "title" }, [
          _vm._v("\n          温馨提示\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "contentInfo" }, [
        _c("div", [
          _vm._v(
            "\n          1、如需修改或者取消订单，请于上门前24小时到下单页面拨打客服电话修改或者取消，否则将视为有效订单。\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n          2、护理员到达后，请合理安排餐饮和住宿。\n        "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }