//
//
//
//
//
//
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {}
};