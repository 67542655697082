var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticStyle: { padding: "0 0.48rem 0rem 0.48rem" } }, [
      _c("div", { staticClass: "label" }, [_vm._v("被保人姓名")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name",
          },
        ],
        staticClass: "input",
        attrs: { type: "text", placeholder: "请输入" },
        domProps: { value: _vm.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.name = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [_vm._v("被保人身份证")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.idCardNo,
            expression: "idCardNo",
          },
        ],
        staticClass: "input",
        attrs: { type: "text", placeholder: "请输入" },
        domProps: { value: _vm.idCardNo },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.idCardNo = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [_vm._v("被保人手机号")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.mobile,
            expression: "mobile",
          },
        ],
        staticClass: "input",
        attrs: { type: "text", placeholder: "请输入" },
        domProps: { value: _vm.mobile },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.mobile = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "label" }, [
        _vm._v("病理报告&出院小结/住院报告"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sub-label" }, [_vm._v("(您可以上传10张照片)")]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-upload",
          {
            staticStyle: { "margin-top": "0.1rem", "margin-left": "0.48rem" },
            attrs: {
              "before-upload": _vm.beforeImgUpload,
              "on-success": _vm.uploadReportImgSuccess,
              "on-remove": _vm.uploadReportImgRemove,
              action: _vm.imgUploadUrl,
              limit: 10,
              "file-list": _vm.reportImgsList,
              "list-type": "picture-card",
              multiple: "",
            },
          },
          [
            _c("i", { staticClass: "el-icon-plus" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "el-upload__tip",
                attrs: { slot: "tip" },
                slot: "tip",
              },
              [_vm._v("只能上传jpg/png文件，且不超过 20 M；")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "label", staticStyle: { "margin-left": "0.48rem" } },
          [_vm._v("身份证照片")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "sub-label",
            staticStyle: { "margin-left": "0.48rem" },
          },
          [_vm._v("(请上传身份证正反面)")]
        ),
        _vm._v(" "),
        _c(
          "el-upload",
          {
            staticStyle: { "margin-top": "0.1rem", "margin-left": "0.48rem" },
            attrs: {
              "before-upload": _vm.beforeImgUpload,
              "on-success": _vm.uploadIdCardImgSuccess,
              "on-remove": _vm.uploadIdCardImgRemove,
              action: _vm.imgUploadUrl,
              limit: 2,
              "file-list": _vm.idCardImgsList,
              "list-type": "picture-card",
              multiple: "",
            },
          },
          [
            _c("i", { staticClass: "el-icon-plus" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "el-upload__tip",
                attrs: { slot: "tip" },
                slot: "tip",
              },
              [_vm._v("只能上传jpg/png文件")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "submit-bt flex", on: { click: _vm.submit } },
          [_vm._v("提交审核")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { height: "0.8rem" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-tip flex" }, [
      _c("div", { staticClass: "desc" }, [
        _vm._v(
          "\n      仅符合重疾发生条件的用户可使用该服务，请先上传相关证明材料，相关重疾说明详见：“xxx”\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }