//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getToken } from '@/utils/auth';
import request from '@/utils/request';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      status: null,
      accessToken: null
    };
  },
  created: function created() {
    this.status = this.$route.query.status;
    this.accessToken = getToken();

    if (this.status == 1) {
      this.wxConfig();
    }

    console.log(this.$route);
    this.initCommonParam();
  },
  computed: {},
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    getPath: function getPath() {
      return "pages/openplatform/onboarding.html?personalIconVisible=0&access_token=" + this.accessToken;
    },
    activeConfirm: function activeConfirm() {
      var _this = this;

      this.$confirm('确定现在激活视频医生服务吗？激活后24小时内有效。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.__active();
      }).catch(function () {});
    },
    __active: function __active() {
      var _this2 = this;

      request({
        url: 'ownership/cpic/active',
        method: 'post',
        data: {
          clientId: this.clientId,
          projectId: this.projectId,
          ownershipId: this.ownershipId
        }
      }).then(function (res) {
        console.log(res);

        if (res.status === 200) {
          _this2.status = 1;

          _this2.$router.push({
            path: '/hqb/detail?status=1',
            replace: true
          });

          _this2.wxConfig();
        }
      });
    }
  }
};