import Cookies from 'js-cookie';
var TokenKey = 'h5_vue_token';
export function getToken() {
  return Cookies.get(TokenKey); // return 'eyJhbGciOiJIUzUxMiIsInppcCI6IkRFRiJ9.eJwti7sOAiEQRX-FTA0JDOzC0tma-AXGgt2ZjVi4hkdiYvx3KSzPued-oPYVIswgYSucGtOgc3oKKwVqRCmWiC4aFKfLaOrRy8Yj6ZXL4NTb_Si5Za4QrzcJj5bH6hdHO85amXVflQu0qDD5oEhTcujJBuLxzqlBNLMzxls9oQR-v_4ieDfh9we3OSrh.pB6ycl-Hk1cGiWYVcwTjU8nTpNEXIbdSr9llQQQivIydBcO-e5XsyJbJrAZlu2tV0eCqZS1KRXA6tB8ARhZQXQ'
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}