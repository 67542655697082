var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wscn-http404-container" }, [
      _c("div", { staticClass: "wscn-http404" }, [
        _c("div", { staticClass: "pic-404" }, [
          _c("img", {
            staticClass: "pic-404__parent",
            attrs: { src: require("@/assets/404_images/404.png"), alt: "404" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "pic-404__child left",
            attrs: {
              src: require("@/assets/404_images/404_cloud.png"),
              alt: "404",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "pic-404__child mid",
            attrs: {
              src: require("@/assets/404_images/404_cloud.png"),
              alt: "404",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "pic-404__child right",
            attrs: {
              src: require("@/assets/404_images/404_cloud.png"),
              alt: "404",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page404" }, [
          _c("div", { staticClass: "page404__title" }, [
            _vm._v("抱歉，您访问的页面不存在。"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page404__message" }, [
            _vm._v(
              "可能是因为输入的链接地址有误，或者您没有权限访问该页面。请点击下面按钮返回首页。"
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }