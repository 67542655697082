import "core-js/modules/es6.typed.uint8-array";
import "core-js/modules/es6.string.starts-with";
import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import hex_md5 from 'md5';
import { getToken } from '@/utils/auth';
import router from "../router"; // 创建axios实例

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api的base_url
  timeout: 300000,
  // 请求超时时间,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'X-M-Platform': 'HTML5',
    'X-M-Version': '0.0.1',
    'X-M-Device': hex_md5(window.localStorage.getItem('ip') + navigator.userAgent)
  }
}); // request拦截器

service.interceptors.request.use(function (config) {
  //TODO
  // if (store.getters.token) {
  //   // let each request carry token
  //   config.headers['authorization'] = getToken()
  // }
  config.headers['authorization'] = getToken();
  return config;
}, function (error) {
  // Do something with request error
  Promise.reject(error);
}); // respone拦截器

service.interceptors.response.use(function (response) {
  /**
   * status为非200是抛错 可结合自己业务进行修改
   */
  var res = response;

  if (res.status !== 200) {
    Message({
      message: res.message,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject('error');
  } else {
    var callBack = response.config.callBack;

    if (typeof callBack === 'function') {
      if (callBack(response)) {
        return response;
      }
    }

    var responseType = response.config.responseType;
    var attachmentFileName = response.config.attachmentFileName;

    if ('arraybuffer' == responseType) {
      var content_type = response.headers['content-type'];

      if (content_type.startsWith('application/json;')) {
        var reader = new FileReader();
        var buf = new Uint8Array(response.data);
        reader.readAsText(new Blob([buf]), 'utf-8');

        reader.onload = function () {
          var result = JSON.parse(reader.result);
          Message({
            message: result.message,
            type: 'error',
            duration: 2 * 1000
          });
        };
      } else {
        var blob = new Blob([response.data], {
          type: content_type
        });
        var a = document.createElement('a');
        var blobURL = URL.createObjectURL(blob);
        var file_name = attachmentFileName;
        a.download = file_name;
        a.href = blobURL;
        a.type = content_type;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }

    return response;
  }
}, function (error) {
  console.log('调用接口失败, error：');
  console.log(error);

  if (error.response) {
    if (error.response.status === 403) {
      router.push({
        path: '/logout-out',
        replace: true
      });
    } else {
      Message({
        message: error.response.data.errorMessage,
        type: 'error',
        duration: 2 * 1000
      });
      return Promise.reject(error);
    }
  } else {
    Message({
      message: error.response.data.errorMessage,
      type: 'error',
      duration: 2 * 1000
    });
  }

  return Promise.reject(error);
});
export default service;