//
//
//
//
//
//
//
//
//
// import request from '@/utils/request'
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      desc: null,
      desc1: "您提交的材料将于24小时内完成审核",
      desc2: "您提交的材料【%不通过原因】，请检查后重新上传，如有疑问，请致电xxxxxx咨询。"
    };
  },
  created: function created() {
    if (this.isAuditPending()) {
      this.desc = this.desc1;
    } else if (this.isAuditFail()) {
      this.desc = this.desc2;
    }
  },
  computed: {
    auditFail: function auditFail() {
      return this.isAuditFail();
    },
    auditPending: function auditPending() {
      return this.isAuditPending();
    },
    img: function img() {
      if (this.isAuditFail()) {
        return "/static/images/kfsp/audit-result/audit-fail.png";
      }

      return "/static/images/kfsp/audit-result/audit-pending.png";
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    isAuditPending: function isAuditPending() {
      return this.$route.path.indexOf('/audit-pending') > -1;
    },
    isAuditFail: function isAuditFail() {
      return this.$route.path.indexOf('/audit-fail') > -1;
    },
    isAuditSuccess: function isAuditSuccess() {
      return this.$route.path.indexOf('/audit-success') > -1;
    },
    goRegister: function goRegister() {
      this.$router.push({
        path: '/hqb/register'
      });
    }
  }
};