var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "order-place",
      on: { touchmove: _vm.onTouchMove },
    },
    [_c("StepDetail", { attrs: { detail: _vm.orderData } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }