var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "process-detail" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "flow" }, [
          _c("div", { staticClass: "title" }, [_vm._v("采样流程")]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _vm._v("口腔粘膜细胞采样流程"),
            _c("span", [_vm._v("（仅供参考，详见说明书）")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "step" }, [
            _vm._v("采集样本30 min前，清水漱口，漱后禁食；"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "step two" }, [
            _vm._v(
              "采样拭子由采样冠、采样套杆和采样推杆三部分组成。使用时根据包装提示，撕开口腔拭子外包装，手拿采样套杆尾端取出拭子（注意：整个取样过程中手不能接触采样冠部分）；"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "step three" }, [
            _vm._v(
              "将有采样冠一端伸入口腔内，用采样冠刮拭口腔内壁在口中内腮上下刮拭40次以上，力度以腮部微凸为准。"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "step four" }, [
            _vm._v(
              "在刮拭后，可单手持采样拭子，用拇指抵住采样推杆采样后用手推采样拭子后杆，将采样冠顶入（或折断入）采样管内，拧紧采样管盖，便可在常温下进行保存。一个采样冠对应一个采样管。（注意采集管上贴好条形码）"
            ),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "process1",
            attrs: { src: "/static/images/gene-test/process1.png" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _vm._v("唾液采集器采样流程"),
            _c("span", [_vm._v("（仅供参考，详见说明书）")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "step" }, [
            _vm._v("采集样本30 min前，清水漱口、漱后禁食；"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "step two" }, [
            _vm._v(
              "放松并按摩脸颊，将唾液吐进漏斗，直至唾液（不含气泡）量达到唾液填充线高度。"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "step three" }, [
            _vm._v(
              "单手持管，确保收集管处于直立状态。用拇指按下防溅盖，直至听到咔哒声，确保盖子盖紧。"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "step four" }, [
            _vm._v(
              "另一只手轻轻拉动外露的封膜条带，使DNA稳定液缓缓流入收集管内，与唾液混合。"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "step five" }, [
            _vm._v(
              "\t保持收集管处于直立状态，拧下漏斗，并用收集管盖将收集管盖好并拧紧。将拧紧后的收集管上下颠倒混匀10~15次，并丢弃漏斗。（注意收集管上贴好条形码）"
            ),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "process2",
            attrs: { src: "/static/images/gene-test/process2.png" },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }