var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "greenRoad" }, [
    _c("img", {
      attrs: { src: "/static/images/staticPage/greenroad.png", alt: "" },
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "appointmentButton",
      on: { click: _vm.showPhoneTip },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isVisible,
            expression: "isVisible",
          },
        ],
        staticClass: "callPhoneModal",
      },
      [
        _c("div", { staticClass: "content" }, [
          _vm._v(
            "\n      绿通服务仅限罹患重疾的客户使用，请确认是否拨打？\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "button" }, [
          _c("div", { staticClass: "close", on: { click: _vm.close } }, [
            _vm._v("\n        取消\n      "),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "call" }, [
      _c("a", { attrs: { href: "tel:400-6065581" } }, [_vm._v("立即拨打")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }