//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { setToken } from '@/utils/auth';
import request from '@/utils/request';
import "./index.scss";
export default {
  name: 'index',
  data: function data() {
    return {
      sending: false,
      timer: 60,
      phoneNumber: '',
      verificationCode: '',
      clientId: null,
      projectId: null,
      ownershipId: null
    };
  },
  created: function created() {
    console.log(this.$route);
    console.log(this.isHqb() + "," + this.isYnhl());
    this.initCommonParam();
    console.log(this.clientId + "," + this.projectId + "," + this.ownershipId); //this.getAuditStatus()
  },
  computed: {},
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {
    __getMobileCode: function __getMobileCode() {
      request({
        url: 'verification/get-mobile-code-hqb',
        method: 'get',
        params: {
          mobile: this.phoneNumber
        }
      }).then(function (res) {});
    },
    goRegister: function goRegister() {
      if (this.isHqb()) {
        this.$router.push({
          path: '/hqb/register'
        });
      } else if (this.isYnhl()) {
        this.$router.push({
          path: '/ynhl/register'
        });
      }
    },
    __time: function __time() {
      if (this.timer - 1 <= 0) {
        this.sending = false;
        this.timer = 60;
        return;
      }

      this.timer--;
      setTimeout(this.__time, 1000);
    },
    sendCode: function sendCode() {
      if (isNull(this.phoneNumber)) {
        this.$message.error("手机号不能为空");
        return;
      }

      if (this.sending) {
        return;
      }

      this.sending = true;
      this.timer = 60;
      setTimeout(this.__time, 1000);

      this.__getMobileCode();
    },
    check: function check() {
      if (isNull(this.phoneNumber)) {
        return '请输入手机号';
      }

      if (isNull(this.verificationCode)) {
        return '请输入验证码';
      }
    },
    login: function login() {
      var _this = this;

      var warn = this.check();

      if (!isNull(warn)) {
        this.$message.error(warn);
        return;
      }

      request({
        url: 'user/login',
        method: 'post',
        data: {
          clientId: this.clientId,
          projectId: this.projectId,
          ownershipId: this.ownershipId,
          phoneNumber: this.phoneNumber,
          verificationCode: this.verificationCode
        }
      }).then(function (res) {
        if (res.status === 200 && res.data.status == 0) {
          //console.log(res)
          setToken(res.data.data.token);

          _this.getAuditStatus();
        } else if (res.data && res.data.errorMessage) {
          _this.$message.error(res.data.errorMessage);
        }
      });
    },
    getAuditStatus: function getAuditStatus() {
      var _this2 = this;

      request({
        url: 'ownership/audit/status',
        method: 'post',
        data: {
          clientId: this.clientId,
          projectId: this.projectId,
          ownershipId: this.ownershipId
        }
      }).then(function (res) {
        if (res.status === 200 && res.data.status == 0) {
          //console.log(res)
          var auditStatus = res.data.data.auditStatus;
          var ownershipStatus = res.data.data.ownershipStatus;

          if (_this2.isHqb()) {
            if (auditStatus == 1) {
              _this2.$router.push({
                path: '/hqb/audit-pending'
              });
            } else if (auditStatus == 2) {
              _this2.$router.push({
                path: '/hqb/detail?status=' + ownershipStatus
              });
            } else if (auditStatus == 3) {
              _this2.$router.push({
                path: '/hqb/audit-fail'
              });
            } else {
              _this2.$router.push({
                path: '/hqb/register'
              });
            }
          } else if (_this2.isYnhl()) {
            if (auditStatus == 1) {
              _this2.$router.push({
                path: '/ynhl/audit-pending'
              });
            } else if (auditStatus == 2) {
              _this2.$router.push({
                path: '/ynhl/audit-success'
              });
            } else if (auditStatus == 3) {
              _this2.$router.push({
                path: '/ynhl/audit-fail'
              });
            } else {
              _this2.$router.push({
                path: '/ynhl/register'
              });
            }
          }
        } else if (res.data && res.data.errorMessage) {
          _this2.$message.error(res.data.errorMessage);
        }
      });
    }
  }
};