import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import wx from 'weixin-js-sdk';
import { setToken } from '@/utils/auth';
export default {
  data: function data() {
    return {
      rightsList: [],
      lastBookedName: ''
    };
  },
  created: function created() {
    var _this = this;

    var authorization = this.getQuery('authorization');

    if (authorization) {
      setToken(authorization);
    }

    request({
      url: 'user/ownership/group/2'
    }).then(function (res) {
      if (res.status === 200) {
        _this.rightsList = res.data.data;
        window.console.log(_this.rightsList);
        var bookedList = [];

        if (Array.isArray(res.data.data)) {
          res.data.data.forEach(function (item) {
            if (Number(item.serviceState) === 0 || Number(item.serviceState) === 1) {
              bookedList.push(item.name);
            }
          });
        }

        _this.lastBookedName = bookedList[bookedList.length - 1];
      }
    });
    var type = this.$route.query.type;

    if (type === 'ONLINE_NURSING_APPOINTMENT') {
      //如果是从订单页路由过来的，则监听返回键事件
      window.addEventListener("popstate", function (e) {
        wx.miniProgram.navigateBack({
          delta: 1
        });
      }, false);
    } else {}
  },
  mounted: function mounted() {
    // setTimeout(function () {
    //     window.addEventListener("popstate", this.returnMiniApp, false);
    // },500)
    window.addEventListener("popstate", this.returnMiniApp, false);
  },
  destroyed: function destroyed() {
    window.removeEventListener("popstate", this.returnMiniApp, false);
  },
  methods: {
    returnMiniApp: function returnMiniApp() {
      // 跳转到上一个小程序页面
      wx.miniProgram.navigateBack({
        delta: 1
      }); // 小程序跳转方法
      // wx.miniProgram.navigateTo({
      //     url:'pages/mine/index',        // 指定跳转至小程序页面的路径
      //     success: (res) => {
      //         console.log(res);   // 页面跳转成功的回调函数
      //     },
      //     fail: (err) => {
      //         console.log(err);   // 页面跳转失败的回调函数
      //     }
      // });
    },
    goDetail: function goDetail(item) {
      // this.$router.push({
      //     path: `/nursing/service-detail`
      // });
      // return;
      //过期return
      if (item.isOverdue) {
        return;
      }

      var name = item.name,
          iconUrl = item.iconUrl,
          originalPrice = item.originalPrice,
          clientId = item.clientId,
          projectId = item.projectId,
          ownershipId = item.ownershipId,
          userOwnershipId = item.userOwnershipId;
      console.log(iconUrl);
      sessionStorage.setItem('appointmentProjectImg', iconUrl);

      if (Number(item.serviceState) === 0 || Number(item.serviceState) === 1 || Number(item.serviceState) === 2) {
        this.$router.push({
          path: '/nursing/order-detail?id=' + userOwnershipId + '&from=complete&type=ONLINE_NURSING_APPOINTMENT'
        });
      } else {
        this.$router.push({
          path: "/nursing/service-detail?title=".concat(name, "&originalPrice=").concat(originalPrice, "&clientId=").concat(clientId, "&projectId=").concat(projectId, "&ownershipId=").concat(ownershipId, "&userOwnershipId=").concat(userOwnershipId)
        });
      }
    }
  }
};