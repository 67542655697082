var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gene-report" }, [
    _c("div", { staticClass: "header" }, [
      _vm._v("申请已提交，试剂将尽快寄出"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "operation", on: { click: _vm.goProcess } }, [
          _vm._v("操作流程"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "label" }, [_vm._v("检测项目：")]),
        _vm._v(" "),
        this.$route.query.id === 1
          ? _c("div", { staticClass: "name" }, [
              _vm._v("儿童安全用药抗感染类(8项）"),
            ])
          : this.$route.query.id === 2
          ? _c("div", { staticClass: "name" }, [
              _vm._v("儿童天赋（男/女 22项）"),
            ])
          : _c("div", { staticClass: "name" }, [
              _vm._v("儿童营养吸收（男/女 12项）"),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "align" }, [
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "label" }, [_vm._v("受检人姓名：")]),
            _vm._v(" "),
            _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.data.name))]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "label" }, [_vm._v("采样棒编号：")]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "name",
                  "serial",
                  { geneSampleNo: _vm.data.geneSampleNo },
                ],
              },
              [_vm._v(_vm._s(_vm.data.geneSampleNo || "采样棒寄出后显示"))]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "instructions" }, [
        _c("span", { staticClass: "call" }, [_vm._v("您好，")]),
        _vm._v("您的"),
        this.$route.query.id === 1
          ? _c("span", [_vm._v("儿童安全用药抗感染类（8项）")])
          : this.$route.query.id === 2
          ? _c("span", [_vm._v("儿童天赋（男/女 22项）")])
          : _c("span", [_vm._v("儿童营养吸收（男/女 12项）")]),
        _vm._v(
          "\n      基因检测申请已提交，实验室会尽快寄出试剂，请在收到试剂盒后按照说明方式进行采样，采样完成按照下方地址寄出，签收后的7个工作日可查看报告。\n    "
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.data.reportUrl
        ? _c(
            "div",
            _vm._l(_vm.data.reportUrl.split(","), function (url, key) {
              return _c("div", { key: key }, [
                _c(
                  "div",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.download(url)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "查看报告" +
                        _vm._s(
                          _vm.data.reportUrl.split(",").length === 1
                            ? null
                            : key + 1
                        )
                    ),
                  ]
                ),
              ])
            }),
            0
          )
        : _c("div", { staticClass: "btn disable" }, [_vm._v("查看报告")]),
    ]),
    _vm._v(" "),
    _vm.modal
      ? _c("div", { staticClass: "modal" }, [
          _c("div", { staticClass: "mask" }),
          _vm._v(" "),
          _c("div", { staticClass: "wrap" }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.hint))]),
            _vm._v(" "),
            _c("div", { staticClass: "confim" }, [
              _c("div", { staticClass: "ok", on: { click: _vm.goMini } }, [
                _vm._v("确定"),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact" }, [
      _c(
        "div",
        { staticClass: "address", staticStyle: { "margin-bottom": "0.32rem" } },
        [
          _c("img", {
            staticClass: "icon",
            attrs: { src: "/static/images/gene-test/address.png" },
          }),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "lable" }, [_vm._v("采样邮寄地址：")]),
            _vm._v(" "),
            _c("div", { staticClass: "value" }, [
              _vm._v(
                "上海市杨浦区翔殷路128号16号楼三楼（上海理工大学科技园）  生息转临床"
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "address" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: "/static/images/gene-test/phone.png" },
        }),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "lable" }, [_vm._v("电话：")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [_vm._v("18317079196")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }