var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.package1, function (item, key) {
      return _c("div", { key: key, staticClass: "root" }, [
        _c("img", {
          staticClass: "img",
          attrs: { src: item.src, id: item.id },
        }),
        _vm._v(" "),
        item.title
          ? _c("div", { staticClass: "title flex" }, [
              _vm._v("\n      " + _vm._s(item.title) + "\n    "),
            ])
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }