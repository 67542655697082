var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-detail" },
    [
      _c("div", { staticClass: "top" }, [
        _c("img", {
          staticClass: "header-bg",
          attrs: { src: "/static/images/kfsp/report-detail/header.png" },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "date" }, [
          _vm._v(_vm._s(_vm.reportDetail.date)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row flex" }, [
        _c("div", { staticClass: "left" }, [_vm._v("咨询信息")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "right",
            staticStyle: { position: "relative" },
            on: { click: _vm.goServiceUserDetail },
          },
          [
            _c("span", { staticStyle: { display: "block" } }, [
              _vm._v("康复师：" + _vm._s(_vm.reportDetail.serviceUserName)),
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticStyle: { display: "block", "margin-top": "-0.4rem" } },
              [_vm._v("咨询时长：" + _vm._s(_vm.reportDetail.__diff))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex bt" }, [_vm._v("查看")]),
          ]
        ),
      ]),
      _vm._v(" "),
      !_vm.checkNull(_vm.reportDetail, "consultResult", "基本情况", "患者信息")
        ? _c("div", { staticClass: "row flex" }, [
            _c("div", { staticClass: "left" }, [_vm._v("患者信息")]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c("span", { staticStyle: { display: "block" } }, [
                _vm._v(
                  _vm._s(_vm.reportDetail.consultResult["基本情况"]["患者信息"])
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.checkNull(_vm.reportDetail, "consultResult", "基本情况", "康护意见")
        ? _c("div", { staticClass: "row flex" }, [
            _c("div", { staticClass: "left" }, [_vm._v("康护意见")]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _vm._v(
                _vm._s(_vm.reportDetail.consultResult["基本情况"]["康护意见"])
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.checkNull(_vm.reportDetail, "consultResult", "基本情况", "既往史")
        ? _c("div", { staticClass: "row flex" }, [
            _c("div", { staticClass: "left" }, [_vm._v("既往史")]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _vm._v(
                _vm._s(_vm.reportDetail.consultResult["基本情况"]["既往史"])
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.checkNull(_vm.reportDetail, "consultResult", "基本情况", "过敏史")
        ? _c("div", { staticClass: "row flex" }, [
            _c("div", { staticClass: "left" }, [_vm._v("过敏史")]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _vm._v(
                _vm._s(_vm.reportDetail.consultResult["基本情况"]["过敏史"])
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row flex" }, [
        _c("div", { staticClass: "left" }, [_vm._v("病例资料")]),
        _vm._v(" "),
        !_vm.checkNull(
          _vm.reportDetail,
          "consultResult",
          "基本情况",
          "病历资料"
        )
          ? _c(
              "div",
              { staticClass: "right flex" },
              _vm._l(
                _vm.reportDetail.consultResult["基本情况"]["病历资料"],
                function (item, key) {
                  return _c("img", {
                    key: key,
                    staticClass: "img",
                    attrs: { src: item },
                    on: {
                      click: function ($event) {
                        return _vm.onClickImg(item)
                      },
                    },
                  })
                }
              ),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.checkNull(_vm.reportDetail, "consultResult", "康复指导")
        ? _c(
            "div",
            { staticClass: "suggest" },
            [
              _c("div", { staticClass: "flex header" }, [
                _vm._v("康护指导建议"),
              ]),
              _vm._v(" "),
              _vm._l(
                Object.entries(_vm.reportDetail.consultResult["康复指导"]),
                function (suggest_item) {
                  return [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(suggest_item[0])),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "desc" }, [
                      _vm._v(_vm._s(suggest_item[1])),
                    ]),
                  ]
                }
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "0.4rem" } }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%", height: "auto" },
            attrs: { src: _vm.dialogImg },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }