import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import request from '@/utils/request';
import wx from 'weixin-js-sdk';
import { setToken } from '@/utils/auth';
export default {
  data: function data() {
    return {
      rightsList: [],
      rightsData: {},
      orderType: '',
      step: 1,
      buttonShow: true
    };
  },
  created: function created() {
    var _this = this;

    var _this$$route$query = this.$route.query,
        from = _this$$route$query.from,
        orderType = _this$$route$query.orderType;

    if (from && from === 'order') {
      this.buttonShow = false;
      this.orderType = orderType;
    } else {
      var authorization = this.getQuery('authorization');

      var _orderType = this.getQuery('orderType');

      var ownershipGroupId = this.getQuery('ownershipGroupId');
      this.orderType = _orderType;

      if (authorization) {
        setToken(authorization);
      }

      request({
        url: 'user/ownership/group/' + ownershipGroupId
      }).then(function (res) {
        if (res.status === 200) {
          _this.rightsList = res.data.data; //因为出院交通安排、就医陪诊、心理咨询只是单个权益，所以只取第一个（不在重新编写后台）

          if (_this.rightsList) {
            _this.rightsData = _this.rightsList[0];
          } //如果已预约则跳转到服务详情页面


          if (_this.rightsData.isOverdue) {
            return;
          }
        }
      });
    }

    if (this.orderType === "LEAVE_HOSPITAL_TRAFFIC_ARRANGE") {
      //出院交通安排
      this.step = 1;
    } else if (this.orderType === "SEE_A_DOCTOR") {
      //就医陪诊
      this.step = 2;
    } else if (this.orderType === "PSYCHOLOGICAL_COUNSELING") {
      //心理咨询
      this.step = 3;
    }
  },
  mounted: function mounted() {
    window.addEventListener("popstate", this.returnMiniApp, false);
  },
  destroyed: function destroyed() {
    window.removeEventListener("popstate", this.returnMiniApp, false);
  },
  methods: {
    returnMiniApp: function returnMiniApp() {
      // 跳转到上一个小程序页面
      wx.miniProgram.navigateBack({
        delta: 1
      });
    },
    goDetail: function goDetail() {
      //过期return
      if (this.rightsData.isOverdue) {
        return;
      }

      console.log("ceshi:" + this.rightsData);
      var _this$rightsData = this.rightsData,
          name = _this$rightsData.name,
          iconUrl = _this$rightsData.iconUrl,
          originalPrice = _this$rightsData.originalPrice,
          clientId = _this$rightsData.clientId,
          projectId = _this$rightsData.projectId,
          ownershipId = _this$rightsData.ownershipId,
          userOwnershipId = _this$rightsData.userOwnershipId,
          orderType = _this$rightsData.orderType;
      sessionStorage.setItem('appointmentProjectImg', iconUrl);

      if (Number(this.rightsData.serviceState) === 0 || Number(this.rightsData.serviceState) === 1 || Number(this.rightsData.serviceState) === 2) {
        this.$router.push({
          path: '/nursing/order-detail?id=' + userOwnershipId
        });
      } else {
        this.$router.push({
          path: "/nursing/complete-info?title=".concat(name, "&originalPrice=").concat(originalPrice, "&clientId=").concat(clientId, "&projectId=").concat(projectId, "&ownershipId=").concat(ownershipId, "&userOwnershipId=").concat(userOwnershipId, "&orderType=").concat(orderType, "&iconUrl=").concat(iconUrl)
        });
      }
    }
  }
};